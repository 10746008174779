<template>
    <div>
        <div class="row">
            <div class="col-md-6 pt-2">
                <div class="col-md-12 pl-0 pr-0 mb-2 d-flex align-items-center">
                    <span class="col-md-8 pl-0 pr-0" style="font-weight: bold;">제어 이벤트 실행 이력</span>
                    <div class="col-md-4 pl-0 pr-0">
                        <date-picker 
                            v-model="searchDate" 
                            class="form-control" 
                            placeholder="날짜 선택"
                            :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }"
                            @dp-change="searchEventList(searchDate)">
                        </date-picker>
                    </div>
                </div>
                <div class="col-md-12 pl-0" style="max-height: 200px; overflow-y: auto;">
                    <table class="table table-bordered table-hover mb-0"
                        style="text-align: center; max-height: 200px; overflow-y: auto;">
                        <thead class="thead-dark">
                            <tr>
                                <th style="width: 140px;">제어 실행시간</th>
                                <th>대상 설비</th>
                                <th>이벤트명</th>
                                <th style="width: 40px;">성공</th>
                                <th style="width: 40px;">실패</th>
                            </tr>
                        </thead> 
                        <tbody v-if="ctrlEventList">
                            <tr v-for="(event, i) in ctrlEventList" :key="i" @click="getSchdEventLogList(event.eventExecIdx)">
                                <td>{{ event.eventDate }}</td>
                                <td>{{ convertTargetEquipText(event.schdEventIdx) }}</td>
                                <td>{{ event.schdEventName }}</td>
                                <td>{{ event.successCnt }}</td>
                                <td>{{ event.failCnt }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5">표시할 데이터가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6 pt-3">
                <div class="col-md-12 pl-0">
                    <p style="font-weight: bold;">제어 처리 결과</p>
                </div>
                <div class="col-md-12 pl-0 pr-0" style="max-height: 200px; overflow-y: auto;">
                    <table class="table table-bordered table-hover mb-0" style="text-align: center; max-height: 200px; overflow-y: auto;">
                        <thead class="thead-dark">
                            <tr>
                                <th class="col-6">처리 시간</th>
                                <th class="col-4">설비</th>
                                <th class="col-2">결과</th>
                            </tr>
                        </thead>
                        <tbody v-if="ctrlLogList">
                            <tr v-for="(log, i) in ctrlLogList" :key="i">
                                <td @click="getSchdEventLogDetail(log.eventExecIdx, log.logDate)">{{ ctrlLogListLogDate[i] }}</td>
                                <td @click="getSchdEventLogDetail(log.eventExecIdx, log.logDate)">{{ convertEquipNameText(log.equipIdx) }}</td>
                                <td @click="getSchdEventLogDetail(log.eventExecIdx, log.logDate)">{{ log.resultCode }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="4">표시할 데이터가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <b-modal ref="detailLogModal" centered id="detailLogModal" title="제어 로그 상세" hide-footer>
            <div v-if="logDetail" class="row pb-3" style="border-bottom: 1px solid rgba(45, 53, 60, .15);">
                <form action="" class="col-md-6">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-4 col-form-label d-flex justify-content-center">설비명 : </label>
                        <div class="col-md-8">
                            <input
                                :value="equipName"
                                type="text"
                                class="form-control" 
                                disabled
                            >
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-6">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-4 col-form-label d-flex justify-content-center">제어모드 : </label>
                        <div class="col-md-8">
                            <input
                                :value="ctrlModeName"
                                type="text" 
                                class="form-control" 
                                disabled
                            >
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-2 col-form-label d-flex justify-content-center">제어명령 : </label>
                        <div class="col-md-10">
                            <textarea :value="JSON.stringify(logDetail.ctrlOutPut)" class="form-control" style="max-height: 80px; height: 80px; resize: none; overflow-y: auto;" disabled></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div v-if="logDetail" class="row mt-3 pb-3" style="border-bottom: 1px solid rgba(45, 53, 60, .15);">
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-3 col-form-label d-flex justify-content-center">제어시작시간 : </label>
                        <div class="col-md-9">
                            <input
                                :value="logDetailSetDt"
                                type="text" 
                                class="form-control" 
                                disabled
                            >
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-3 col-form-label d-flex justify-content-center">제어종료시간 : </label>
                        <div class="col-md-9">
                            <input
                                :value="logDetailUpdDt"
                                type="text" 
                                class="form-control" 
                                disabled
                            >
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-3 col-form-label d-flex justify-content-center">제어결과 : </label>
                        <div class="col-md-9">
                            <input
                                :value="logDetail.state"
                                type="text" 
                                class="form-control"
                                disabled
                            >
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-3 col-form-label d-flex justify-content-center">제어상세내용 : </label>
                        <div class="col-md-9">
                            <textarea :value="resultMsg" class="form-control" style="max-height: 80px; height: 80px; resize: none; overflow-y: auto;" disabled></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div v-if="logDetail" class="row mt-3">
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-3 col-form-label d-flex justify-content-center">연동장치 : </label>
                        <div class="col-md-9">
                            <input
                                :value="logDetail.serverName"
                                type="text" 
                                class="form-control" 
                                disabled
                            >
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-3 col-form-label d-flex justify-content-center">연동 프로토콜 : </label>
                        <div class="col-md-9">
                            <input
                                :value="logDetail.driverType"
                                type="text" 
                                class="form-control" 
                                disabled
                            >
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-12">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-md-3 col-form-label d-flex justify-content-center">연동위치 : </label>
                        <div class="col-md-9">
                            <input
                                :value="`${logDetail.channelName} / ${logDetail.facilityName}`"
                                type="text" 
                                class="form-control"
                                disabled
                            >
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';
import moment from 'moment';

export default {
    props: {},
    data() {
        return {
            ctrlEventList: null,
            ctrlLogList: null,
            ctrlLogListLogDate: [],
            searchDate: "",
            equipList: null,
            logDetail: null,
            ctrlModeList: null,
            equipName: null,
            ctrlModeName: null,
            logDetailSetDt: null,
            logDetailUpdDt: null,
            resultMsg: null,
        }
    },
    computed: {
        setScheduleDetail() {
            return this.$store.getters.SET_SCHEDULE_DETAIL;
        }
    },
    async created() {
        this.searchDate = moment(new Date()).format("YYYY-MM-DD");
        await this.getSchdEventHistList();
        await this.getEquipList();
        await this.getEquipCtrlList();
        // if (this.setScheduleDetail.stat.lastCtrlDate) {
        //     this.setScheduleDetail.stat.lastCtrlDate = moment(this.setScheduleDetail.stat.lastCtrlDate).format("YYYY-MM-DD hh:mm:ss")
        // }
    },
    mounted() {
    },
    methods: {
        async getEquipList() {
            try {
                let result = await backEndApi.equipInfo.getEquipList();
                this.equipList = result.data;
            } catch (e) {
                console.error(e)

            }
        },
        async getSchdEventHistList() {
            try {
                let result = await backEndApi.equipControl.getSchdEventHistList(this.setScheduleDetail.schdCtrlIdx, this.searchDate);
                if (result.data) {
                    this.ctrlEventList = result.data
                    if (this.ctrlEventList.length > 0) {
                        for (let i = 0; i < this.ctrlEventList.length; i++) {
                            this.ctrlEventList[i].eventDate = moment.utc(this.ctrlEventList[i].eventDate).local().format("YYYY-MM-DD HH:mm:ss")
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getSchdEventLogList(eventExecIdx) {
            this.ctrlLogListLogDate = [];
            try {
                let result = await backEndApi.equipControl.getSchdEventLogList(eventExecIdx);
                this.ctrlLogList = result.data;
                if(result.data) {
                    this.ctrlLogList = result.data;
                    for(let i = 0; i < this.ctrlLogList.length; i++) {
                        this.ctrlLogListLogDate.push(moment(this.ctrlLogList[i].logDate).format("YYYY-MM-DD HH:mm:ss"))
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        async searchEventList(searchDate) {
            this.ctrlEventList = null;
            this.ctrlLogList = null;
            try {
                let result = await backEndApi.equipControl.getSchdEventHistList(this.setScheduleDetail.schdCtrlIdx, searchDate);
                this.ctrlEventList = result.data;
                if (this.ctrlEventList.length > 0) {
                    for (let i = 0; i < this.ctrlEventList.length; i++) {
                        this.ctrlEventList[i].eventDate = moment(this.ctrlEventList[i].eventDate).format("YYYY-MM-DD HH:mm:ss")
                    }
                }
            } catch (e) {
                console.error(e)
            }
        },
        async getSchdEventLogDetail(eventExecIdx, logDate) {
            this.$refs.detailLogModal.show();
            try {
                let result = await backEndApi.equipControl.getSchdEventLogDetail(eventExecIdx, logDate);
                if(result.data) {
                    this.logDetail = result.data;
                    this.equipName = this.logDetail.targetIdx ? this.equipList.find((v) => v.equipIdx == this.logDetail.targetIdx).equipName : "";
                    // this.ctrlModeName = this.logDetail.ctrlMode ? this.ctrlModeList.find((v) => v.ctrlCode == this.logDetail.ctrlMode).equipCtrlName : "";
                    this.ctrlModeName = this.logDetail.ctrlMode ? this.logDetail.ctrlMode : "-"
                    this.logDetailSetDt = this.logDetail.setDt ? moment(this.logDetail.setDt).format("YYYY-MM-DD HH:mm:ss") : "-";
                    this.logDetailUpdDt = this.logDetail.updDt ? moment(this.logDetail.updDt).format("YYYY-MM-DD HH:mm:ss") : "-";
                    this.resultMsg = this.ctrlLogList.find((item) => item.eventExecIdx == eventExecIdx).resultMsg;
                } 
            } catch (e) {
                console.error(e);
            }
        },
        async getEquipCtrlList() {
            try {
                let result = await backEndApi.equipControl.getEquipCtrlList();
                if(result.data) {
                    this.ctrlModeList = result.data
                    console.log(this.ctrlModeList.find((v) => v.ctrlCode = 'CTRL'));
                }
            } catch (e) {
                console.error(e);
            }
        },
        convertResultText(resultCode) {
            let temp;
            if (resultCode == 'Success') {
                temp = "성공"
            } else {
                temp = "실패"
            }
            return temp;
        },
        convertTargetEquipText(schdEventIdx) {
            let equipType = this.setScheduleDetail.events.find((v) => v.schdEventIdx == schdEventIdx).equipType
            let equipText;
            if (equipType == "IDU" || equipType == "KIEMECS-CTRL") {
                equipText = "에어컨";
            } else if (equipType == "LGT") {
                equipText = "조명";
            } else {
                equipText = "";
            }
            return equipText;
        },
        convertEquipNameText(equipIdx) {
            let equipNameText;
            let temp = this.equipList.find((v) => v.equipIdx == equipIdx);
            if (temp) {
                equipNameText = temp.equipName;
            } else {
                equipNameText = "";
            }
            return equipNameText;
        },
    },
}

</script>

<style scoped>
.selected-row {
    background-color: #736d6d;
}
</style>