<template>
    <div>
        <!-- 스케줄 편집 -->
        <div v-if="setScheduleDetail" class="row">
            <form class="col-md-12">
                <div class="col-12 d-flex align-items-center p-0">
                    <div class="col-12 p-0 d-flex justify-content-end">
                        <button type="button" class="btn btn-sm btn-secondary mr-1" @click="openExceptModal()">예외일 불러오기</button>
                        <button type="button" class="btn btn-warning btn-sm mr-1 p-0" @click="openUserExceptModal()" style="width: 30px; height: 30px;">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" @click="deleteExceptConfig()" style="width: 30px; height: 30px;">-</button>
                    </div>
                </div>
            </form>
            <div class="col-md-12 pt-2" style="max-height: 200px; overflow-y: auto;">
                <table class="table table-bordered table-hover" style="text-align: center;">
                    <thead class="thead-dark">
                        <tr>
                            <th class="col-1">선택</th>
                            <th class="col-2">사용 상태</th>
                            <th class="col-6">예외일명</th>
                            <th class="col-3">예외일자</th>
                        </tr>
                    </thead>
                    <tbody v-if="exceptTemp.length > 0">
                        <tr v-for="(except, i) in exceptTemp" :key="i">
                            <td class="text-center valign-middle">
                                <div class="form-group form-check d-flex justify-content-center align-items-center">
                                    <input v-model="except.checked" class="form-check-input" type="checkbox">
                                </div>
                            </td>
                            <td class="text-center valign-middle">
                                <div class="custom-control custom-switch">
                                    <input v-model="except.toggled" type="checkbox" class="custom-control-input" :id="`except-switch${i}`" @change="changeExceptState()">
                                    <label class="custom-control-label" :for="`except-switch${i}`"></label>
                                </div>
                            </td>
                            <td class="text-center valign-middle">{{ except.exceptName }}</td>
                            <td class="text-center valign-middle">{{ except.exceptDt }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">표시할 데이터가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- 스케줄 추가 -->
        <div v-else class="row">
            <form class="col-md-12">
                <div class="col-12 d-flex align-items-center p-0">
                    <div class="col-12 p-0 d-flex justify-content-end">
                        <button type="button" class="btn btn-sm btn-secondary mr-1" @click="openExceptModal()">예외일 불러오기</button>
                        <button type="button" class="btn btn-warning btn-sm mr-1 p-0" @click="openUserExceptModal()" style="width: 30px; height: 30px;">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" style="width: 30px; height: 30px;" @click="deleteExceptConfig()">-</button>
                    </div>
                </div>
            </form>
            <div class="col-md-12 pt-2" style="max-height: 200px; overflow-y: auto;">
                <table class="table table-bordered table-hover" style="text-align: center;">
                    <thead class="thead-dark">
                        <tr>
                            <th class="col-1">선택</th>
                            <th class="col-2">사용 상태</th>
                            <th class="col-2">예외일명</th>
                            <th class="col-7">예외일자</th>
                        </tr>
                    </thead>
                    <tbody v-if="exceptConfig.length > 0">
                        <tr v-for="(except, i) in exceptConfig" :key="i">
                            <td class="text-center valign-middle">
                                <div class="form-group form-check d-flex justify-content-center align-items-center">
                                    <input v-model="except.checked" class="form-check-input" type="checkbox">
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-switch">
                                    <input v-model="except.toggled" type="checkbox" class="custom-control-input" :id="`except-switch${i}`" @change="changeExceptState()">
                                    <label class="custom-control-label" :for="`except-switch${i}`"></label>
                                </div>
                            </td>
                            <td class="text-center valign-middle">{{ except.exceptName }}</td>
                            <td class="text-center valign-middle">{{ except.exceptDt }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">표시할 데이터가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- 시스템에 등록된 공휴일 또는 기존 스케줄에 등록된 예외일 선택 모달 -->
        <b-modal centered ref="exceptModal" id="exceptModal" hide-header @hidden="closeExceptModal()">
            <template #modal-footer>
                <button type="button" class="btn btn-sm btn-primary" @click="addExceptConfig()">추가</button>
                <button type="button" class="btn btn-sm btn-secondary" @click="closeExceptModal()">닫기</button>
            </template>
            <div class="row" style="border: 1px solid #d5dbe0; border-radius: 5px;">
                <div class="col-md-12 p-1">
                    <div class="col-md-12 mt-1 pl-2 pr-2">
                        <span style="font-size: 13px; font-weight: bold;">예외일 선택</span>
                    </div>
                </div>
                <div class="col-md-8 pt-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group"> 
                                <select v-model="holiday" class="form-control" id="exampleFormControlSelect1" @change="selectHoliday()">
                                    <option :value="null">선택</option>
                                    <option value="systemHoliday">공휴일 선택</option>
                                    <option v-for="(holi, i) in scheduleDetails" :key="i" :value="holi.schdCtrlName">{{ holi.schdCtrlName }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" style="height: 150px; max-height: 150px; overflow-y: auto; border: 1px solid #d5dbe0; border-radius: 5px;">
                <div class="col-md-12 p-1 d-flex">
                    <div class="col-md-10 mt-2 ml-2">
                        <span style="font-size: 13px; font-weight: bold;">목록</span>
                    </div>
                </div>
                <table class="table table-bordered table-hover ml-4 mr-4" style="text-align: center;">
                    <thead class="thead-dark">
                        <tr>
                            <th class="col-4">예외일자</th>
                            <th class="col-8">예외일명</th>
                        </tr>
                    </thead>
                    <tbody v-if="setExceptConfigList.length > 0">
                        <tr v-for="(except, i) in setExceptConfigList" :key="i">
                            <td class="text-center valign-middle">{{ except.exceptName }}</td>
                            <td class="text-center valign-middle">{{ except.exceptDt }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2">표시할 데이터가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <!-- 사용자 지정 예외일 입력 모달 -->
        <b-modal ref="userAddExceptDay" centered @hidden="closeUserExceptModal()">
            <template #modal-header>
                <span>사용자 지정 예외일</span>
            </template>
            <template #modal-footer>
                <button type="button" class="btn btn-sm btn-primary" @click="addUserSelectHoilday(userApExceptName, userApExceptDt)">추가</button>
                <button type="button" class="btn btn-sm btn-secondary" @click="closeUserExceptModal()">닫기</button>
            </template>
            <div class="col-md-12 p-1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row align-items-center mb-1">
                                <label for="schdDesc" class="col-sm-3 col-form-label d-flex justify-content-center">예외일명 :</label>
                                <div class="col-sm-8 pl-0 pr-0">
                                    <input v-model="userApExceptName" class="form-control" id="schdDesc" type="text">
                                </div>
                            </div>
                            <div class="form-group row align-items-center mb-1">
                                <label for="setHour" class="col-sm-3 col-form-label d-flex justify-content-center">예외일 : </label>
                                <div class="col-sm-8 pl-0 pr-0">
                                    <date-picker v-model="userApExceptDt" class="form-control" placeholder="예외일" vertical :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }"></date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';

export default {
    props: {
        exceptConfig: Array,
    },
    components: {},
    created() {
        this.initSetting();
    },
    mounted() {},
    data() {
        return {
            baseHoliData: {
                baseDate: String(new Date().getFullYear()),
            }, // 휴일 목록 API 호출을 위한 base parameter

            holiday: null, // 예외일 선택 v-model 변수
            userApExceptName: "", // 예외일명 v-model 변수
            userApExceptDt: "", // 예외일 v-model 변수

            holidayList: null, // 시스템에 등록된 휴일 목록을 담는 변수
            setExceptConfigList: [], // 예외일을 추가 하기 전에 데이터를 가지고 있는 변수

            exceptTemp: null, // 스케줄 편집할 떄 원본 데이터를 담는 변수
        }
    },
    computed: {
        scheduleDetails() {
            return this.$store.getters.GET_SCHEDULE_DETAILS;
        },
        setScheduleDetail() {
            return this.$store.getters.SET_SCHEDULE_DETAIL;
        }
    },
    watch: {
        exceptTemp: {
            deep: true,
            handler(newVal) {
                if (this.setScheduleDetail) {
                    this.setScheduleDetail.exceptConfig = newVal;
                }
            }
        }
    },
    methods: {
        baseInit() {
            this.holiday = null;
            this.userApExceptName = "";
            this.userApExceptDt = "";
        },
        // 스케줄 편집 시 기존 데이터에 필요한 값을 추가하고 데이터 복사
        initSetting() {
            if (this.setScheduleDetail) {
                this.setScheduleDetail.exceptConfig.forEach(item => {
                    item.checked = false;
                    item.enabled === "Y" ? item.toggled = true : item.toggled = false;
                });
                this.exceptTemp = this.setScheduleDetail.exceptConfig.map(item => { return {...item} });
            }
        },
        // 시스템에 등록된 휴일 목록을 가져오는 함수
        async getHolidayList() {
            try {
                let result = await backEndApi.calendar.getHolidayList(this.baseHoliData);
                if (result.data) {
                    this.holidayList = result.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        // 예외일 추가
        addExceptConfig() {
            if(this.setScheduleDetail) { // 스케줄 편집 모드
                this.setExceptConfigList.forEach(item1 => { // 중복 제거
                    if(!this.exceptTemp.some(item2 => item2.exceptDt === item1.exceptDt)) this.exceptTemp.push(item1);
                })
                this.exceptTemp.sort((a, b) => new Date(a.exceptDt) - new Date(b.exceptDt));
            } else { // 스케줄 추가 모드
                this.setExceptConfigList.forEach(item1 => { // 중복 제거
                    if(!this.exceptConfig.some(item2 => item2.exceptDt === item1.exceptDt)) this.exceptConfig.push(item1);
                })
                this.exceptConfig.sort((a, b) => new Date(a.exceptDt) - new Date(b.exceptDt));
            }
            this.$refs.exceptModal.hide();
            this.setExceptConfigList = [];
            this.baseInit();
        },
        async selectHoliday() {
            if(this.holiday == 'systemHoliday') { // 시스템에 등록된 공휴일 선택
                this.setExceptConfigList = [];
                await this.getHolidayList();
                this.holidayList.forEach((item) => this.setExceptConfigList.push({
                    exceptName: item.holiName,
                    exceptDt: item.baseDate,
                    enabled: "Y",
                    checked: false,
                    toggled: true,
                }))
            } else if (this.holiday == null) {
                this.setExceptConfigList = [];
            } else { // 다른 스케줄에 등록된 예외일 선택
                this.setExceptConfigList = [];
                let temp = this.scheduleDetails.find((item) => item.schdCtrlName == this.holiday) 
                temp.exceptConfig.forEach((item) => this.setExceptConfigList.push({
                    exceptName: item.exceptName,
                    exceptDt: item.exceptDt,
                    enabled: "Y",
                    checked: false,
                    toggled: true,
                }))
            }
        },
        addUserSelectHoilday(exceptName, exceptDt) {
            let temp = {
                exceptName: exceptName,
                exceptDt: exceptDt,
                enabled: "Y",
                checked: false,
                toggled: true,
            }
            if(this.setScheduleDetail) { // 스케줄 편집 모드
                this.exceptTemp.push(temp);
                this.exceptTemp.sort((a, b) => new Date(a.exceptDt) - new Date(b.exceptDt));
            } else { // 스케줄 추가 모드
                this.exceptConfig.push(temp);
                this.exceptConfig.sort((a, b) => new Date(a.exceptDt) - new Date(b.exceptDt));
            }
            this.$refs.userAddExceptDay.hide();
            this.baseInit();
        },
        // 예외일 삭제
        deleteExceptConfig() {
            if(this.setScheduleDetail) { // 스케줄 편집 모드
                this.exceptTemp = this.exceptTemp.filter(item => item.checked == false);
            } else { // 스케줄 추가 모드
                this.exceptConfig = this.exceptConfig.filter(item => item.checked == false);
            }
        },
        // 예외일 상태 변경
        changeExceptState() {
            if(this.setScheduleDetail) { // 스케줄 편집 모드
                this.exceptTemp.forEach((item, index) => {
                    item.toggled === true ? this.setScheduleDetail.exceptConfig[index].enabled = "Y" : this.setScheduleDetail.exceptConfig[index].enabled = "N"
                })
            } else { // 스케줄 추가 모드
                this.exceptConfig.forEach((item, index) => {
                    item.toggled === true ? this.exceptConfig[index].enabled = "Y" : this.exceptConfig[index].enabled = "N"
                })
            }
        },
        openExceptModal() {
            this.$refs.exceptModal.show();
        },
        openUserExceptModal() {
            this.$refs.userAddExceptDay.show();
        },
        closeExceptModal() {
            this.$refs.exceptModal.hide();
            this.baseInit();
            this.setExceptConfigList = [];
        },
        closeUserExceptModal() {
            this.$refs.userAddExceptDay.hide();
            this.baseInit();
        },
    },
}
</script>

<style scoped></style>