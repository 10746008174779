<template>
    <div style="margin-bottom: 10px;">
        <p v-b-toggle.schdSetting class="col-12 schd-setting-list" @click="toggleStatus = !toggleStatus">
            <!-- <span>{{ toggleStatus ? '스케줄 제어 정보' : `스케줄 제어 정보 (${schdDetailItem.schdCtrlName})` }}</span> -->
            <span>{{ `스케줄 제어 정보 (일련번호: ${setScheduleDetail.schdCtrlIdx})` }}</span>
            <span class="when-opened">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
            <span class="when-closed">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
            </span>
        </p>
        <b-collapse visible ref="editCollapse" id="schdSetting"
            style="border: 1px solid #d5dbe0; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
            <div class="row" style="height: 260px;">
                <form action="" class="col-md-7 mt-2">
                    <div class="form-group row align-items-center mb-2">
                        <label for="name" class="col-sm-3 col-form-label d-flex justify-content-center">스케줄명 :</label>
                        <div class="col-sm-9">
                            <input v-model="setScheduleDetail.schdCtrlName" type="text" class="form-control" id="name" placeholder="스케줄명">
                        </div>
                    </div>
                    <div class="form-group row align-items-center mb-2">
                        <label for="select-date" class="col-sm-3 col-form-label d-flex justify-content-center">요일선택 :</label>
                        <div id="select-date" class=" col-sm-9 form-group row align-items-center justify-content-center mb-0 pr-0" style="padding-left: 18px;">
                            <div class="col-sm-12 d-flex align-items-center justify-content-around"
                                style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 7px;">
                                <div v-for="(day, i) in day" :key="i" class="form-check mr-1">
                                    <input v-model="setScheduleDetail.repeatConfig[day.key]" class="form-check-input" type="checkbox" :id="day.key">
                                    <label class="form-check-label" :for="day.key">{{ day.value }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-center mb-2">
                        <label for="date" class="col-sm-3 col-form-label d-flex justify-content-center">적용기간 :</label>
                        <div class="col-sm-9 d-flex align-items-center justify-content-center" id="date">
                            <date-picker v-model="setScheduleDetail.startDate" class="form-control col-sm-5" placeholder="시작일" vertical :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }"></date-picker>
                            <span class="col-sm-2 d-flex justify-content-center" style="padding: 0px 5px 0px 5px;">~</span>
                            <date-picker v-model="setScheduleDetail.endDate" class="form-control col-sm-5" placeholder="종료일" vertical :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }"></date-picker>
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <label for="schdDesc" class="col-sm-3 col-form-label d-flex justify-content-center">스케줄 설명 :</label>
                        <div class="col-sm-9">
                            <textarea v-model="setScheduleDetail.description" class="form-control" id="schdDesc" style="height: 100px; resize: none; overflow-y: auto;"></textarea>
                        </div>
                    </div>
                </form>
                <form action="" class="col-md-5 mt-2 pr-4">
                    <div class="form-group row">
                        <div class="col-sm-12 d-flex mb-2 align-items-center">
                            <label class="col-sm-3 col-form-label p-0" for="zone">적용구역 : </label>
                            <select v-model="setScheduleDetail.zoneIdx" class="form-control" id="zone">
                                <option v-for="(zone, i) in zoneInfo" :key="i" :value="zone.zoneIdx">{{ zone.zoneName }}</option>
                            </select>
                        </div>
                        <div class="col-sm-12">
                            <label class="col-form-label" for="ctrlKind">설비 유형</label>
                            <div id="ctrlKind">
                                <div style="height: 149px; border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px;">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="ehpi" v-model="ehpCheck" @change="targetAddEditEhp()">
                                        <label class="form-check-label" for="ehpi">에어컨</label>
                                    </div>
                                    <div class="form-check mt-1">
                                        <input class="form-check-input" type="checkbox" id="lit" v-model="litCheck" @change="targetAddEditLit()">
                                        <label class="form-check-label" for="lit">조명</label>
                                    </div>
                                    <div class="form-check mt-1">
                                        <input class="form-check-input" type="checkbox" id="kieMqtt" v-model="kieMqtt" @change="targetAddEditKieMqtt()">
                                        <label class="form-check-label" for="kieMqtt">KIEMECS</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </b-collapse>
        <div class="mt-3">
            <b-card no-body>
                <b-tabs card>
                    <b-tab v-if="ehpCheck" title="에어컨 제어 대상">
                        <EhpTab :equipGroup="schdItem.groups" :equip="schdItem.equips" :zoneInfo="zoneInfo"></EhpTab>
                    </b-tab>
                    <b-tab v-if="litCheck" title="조명 제어 대상">
                        <LitTab :equipGroup="schdItem.groups" :equip="schdItem.equips" :zoneInfo="zoneInfo"></LitTab>
                    </b-tab>
                    <b-tab v-if="kieMqtt" title="KIEMECS 제어 대상">
                        <KieMqttTab :equipGroup="schdItem.groups" :equip="schdItem.equips" :zoneInfo="zoneInfo"></KieMqttTab>
                    </b-tab>
                    <b-tab title="스케줄 설정">
                        <SchdSettingTab :ehpCheck="ehpCheck" :litCheck="litCheck" :kieMqtt="kieMqtt" :event="schdItem.events"></SchdSettingTab>
                    </b-tab>
                    <b-tab title="예외일 설정">
                        <ExceptionDayTab :exceptConfig="schdItem.exceptConfig"></ExceptionDayTab>
                    </b-tab>
                    <b-tab title="제어 실행 이력">
                        <MonitoringTab></MonitoringTab>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>

<script>
// import backEndApi from "@api/backEndApi.js";
import EhpTab from "./tab/EhpTab.vue"
import LitTab from "./tab/LitTab.vue"
import KieMqttTab from "./tab/KieMqttTab.vue"
import SchdSettingTab from "./tab/SchdSettingTab.vue"
import ExceptionDayTab from "./tab/ExceptionDayTab.vue"
import MonitoringTab from "./tab/MonitoringTab.vue"
import moment from 'moment';
import backEndApi from "@src/api/backEndApi"

export default {
    props: {
        schdItem: Object,
        // ehpCheck: Boolean,
        // litCheck: Boolean,
    },
    components: {
        EhpTab,
        LitTab,
        KieMqttTab,
        SchdSettingTab,
        ExceptionDayTab,
        MonitoringTab,
    },
    created() {
        this.init();
    },
    mounted() {},
    data() {
        return {
            schdName: null,
            selectDay: null,
            schdStartDate: null,
            schdEndDate: null,
            schdDesc: null,
            zoneInfo: [],
            equipList: [],
            toggleStatus: false,
            ehpCheck: false,
            litCheck: false,
            kieMqtt: false,
            day: [
                { key: "Mon", value: "월"},
                { key: "Tue", value: "화"},
                { key: "Wed", value: "수"},
                { key: "Thu", value: "목"},
                { key: "Fri", value: "금"},
                { key: "Sat", value: "토"},
                { key: "Sun", value: "일"},
            ],
        }
    },
    computed: {
        setScheduleDetail() {
            return this.$store.getters.SET_SCHEDULE_DETAIL;
        }
    },
    watch: {
        setScheduleDetail: {
            handler: function() {
                this.formatData(); // setData() 함수 호출
            },
            deep: true
        }
    },
    unmounted() {
        console.log("asdfasdfasdf");
    },
    methods: {
        init() {
            this.formatData();
            this.getZoneInfo();
            this.schdCtrlKindCheck();
            // this.getEquipGrpList();
            // this.getEquipList();
        },
        formatData() {
            if(this.setScheduleDetail) {
                this.setScheduleDetail.startDate = moment(this.setScheduleDetail.startDate).format("YYYY-MM-DD");
                this.setScheduleDetail.endDate = moment(this.setScheduleDetail.endDate).format("YYYY-MM-DD");
            }
        },
        async getZoneInfo() {
            let result = await backEndApi.zoneInfo.getZoneInfoList();
            result.data.forEach((v) => this.zoneInfo.push(v))
        },
        schdCtrlKindCheck() {
            for(let i = 0; i < this.setScheduleDetail.targets.length; i++) {
                if(this.setScheduleDetail.targets[i].equipType == "IDU") {
                    this.ehpCheck = true;
                }
                if(this.setScheduleDetail.targets[i].equipType == "LGT") {
                    this.litCheck = true;
                }
                if(this.setScheduleDetail.targets[i].equipType == "KIEMECS-CTRL") {
                    this.kieMqtt = true;
                }
            }
        },
        targetAddEditEhp() {
            if (this.ehpCheck) {
                console.log("ehpcheck true")
                // 에어컨 체크가 true일 때
                if (!this.setScheduleDetail.targets.some(item => item.equipType == "IDU")) {
                    console.log("ehp add")
                    // 이미 배열에 해당 항목이 없다면 추가
                    this.setScheduleDetail.targets.push({
                        equipType: "IDU"
                    });
                    this.alertNoti("에어컨 제어를 사용합니다. 아래의 에어컨 제어 탭에서 제어 설비를 선택할 수 있습니다.");
                }
            } else {
                console.log("ehp delete")
                // 에어컨 체크가 false일 때
                this.setScheduleDetail.targets = this.setScheduleDetail.targets.filter(item => item.equipType !== "IDU");
                this.alertNoti("에어컨 제어를 사용하지 않습니다.");
            }
        },
        targetAddEditLit() {
            if (this.litCheck) {
                console.log("lit check true")
                // 조명 체크가 true일 때
                if (!this.setScheduleDetail.targets.some(item => item.equipType == "LGT")) {
                    console.log("lit add")
                    // 이미 배열에 해당 항목이 없다면 추가
                    this.setScheduleDetail.targets.push({
                        equipType: "LGT"
                    });
                    this.alertNoti("조명 제어를 사용합니다. 아래의 조명 제어 탭에서 제어 설비를 선택할 수 있습니다.");
                }
            } else {
                console.log("lit delete")
                // 조명 체크가 false일 때
                this.setScheduleDetail.targets = this.setScheduleDetail.targets.filter(item => item.equipType !== 'LGT');
                this.alertNoti("조명 제어를 사용하지 않습니다.");
            }
        },
        targetAddEditKieMqtt() {
            if (this.kieMqtt) {
                // 조명 체크가 true일 때
                if (!this.setScheduleDetail.targets.some(item => item.equipType == "KIEMECS-CTRL")) {
                    // 이미 배열에 해당 항목이 없다면 추가
                    this.setScheduleDetail.targets.push({
                        equipType: "KIEMECS-CTRL"
                    });
                    this.alertNoti("KIEMECS 제어를 사용합니다. 아래의 KIEMECS 제어 탭에서 제어 설비를 선택할 수 있습니다.");
                }
            } else {
                // 조명 체크가 false일 때
                this.setScheduleDetail.targets = this.setScheduleDetail.targets.filter(item => item.equipType !== 'KIEMECS-CTRL');
                this.alertNoti("KIEMECS 제어를 사용하지 않습니다.");
            }
        },
        async deleteSchdItem() {
            const result = await this.alertConfirmWarning("해당 스케줄을 삭제하시겠습니까?");
            if (!result.value) return;
        },
    },
}
</script>

<style scoped>
.schd-setting-list {
    background-color: #f59c1a;
    padding: 10px;
    margin-bottom: 0px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

.table-wrapper {
    overflow-x: auto;
}

.table-body-wrapper {
    overflow-y: auto;
    max-height: 300px;
    /* 테이블 바디의 최대 높이를 조절하세요 */
}
</style>