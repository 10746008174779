<template>
    <div>
        <div v-if="setScheduleDetail" class="row">
            <form class="col-md-12">
                <div class="col-12 d-flex align-items-center p-0">
                    <div class="form-group col-4 p-0 mb-0">
                        <select v-model="items.selectEquipKind" @change="filterEquipKind()" class="form-control"
                            id="equipKind">
                            <option value="">설비 유형</option>
                            <option value="IDU">에어컨</option>
                            <option value="LGT">조명</option>
                            <option value="KIEMECS-CTRL">KIEMECS</option>
                        </select>
                    </div>
                    <div class="col-8 p-0 d-flex justify-content-end">
                        <button type="button" centered class="btn btn-warning btn-sm mr-1 p-0"
                            style="width: 25px; height: 25px;" @click="openSchdSettingModal()">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" style="width: 25px; height: 25px;"
                            @click="editDeleteSchdSetting()">-</button>
                    </div>
                </div>
            </form>
            <div class="col-md-12 pt-2" style="max-height: 250px; overflow-y: auto;">
                <table class="table table-bordered table-hover" style="text-align: center;">
                    <thead class="thead-dark">
                        <tr>
                            <th>{{ $t("선택") }}</th>
                            <th>{{ $t("이벤트 명") }}</th>
                            <th>{{ $t("제어 시간") }}</th>
                            <th>{{ $t("제어 유형") }}</th>
                            <th>{{ $t("제어 모드") }}</th>
                            <th>{{ $t("제어 설정") }}</th>
                            <th>{{ $t("변경일자") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in scheSettingList" :key="index">
                            <th>
                                <div class="form-check">
                                    <input v-model="schdSettingCheckList[index]" class="form-check-input"
                                        type="checkbox">
                                </div>
                            </th>
                            <td class="valign-middle"
                                @click="editFixSchdSetting(index, scheSettingList[index].equipType)">{{
                                    item.schdEventName }}</td>
                            <td class="valign-middle"
                                @click="editFixSchdSetting(index, scheSettingList[index].equipType)">{{ item.schdHour }}
                            </td>
                            <td class="text-center valign"
                                @click="editFixSchdSetting(index, scheSettingList[index].equipType)">{{ item.equipType
                                }}</td>
                            <td class="text-center valign"
                                @click="editFixSchdSetting(index, scheSettingList[index].equipType)">{{ item.ctrlMode }}
                            </td>
                            <td class="text-center valign"
                                @click="editFixSchdSetting(index, scheSettingList[index].equipType)">{{ item.ctrlCommand
                                }}</td>
                            <td class="text-center valign"
                                @click="editFixSchdSetting(index, scheSettingList[index].equipType)">{{ item.modDate }}
                            </td>
                        </tr>
                        <tr v-if="scheSettingList.length == 0">
                            <td colspan="4">표시할 데이터가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <b-modal ref="editSchdSetting" centered id="schdSetting" hide-header @hidden="hideCtrlSetting">
                <template #modal-footer="{ cancel }">
                    <button type="button" class="btn btn-sm btn-primary" @click="setEditSchdSetting()">저장</button>
                    <button type="button" class="btn btn-sm btn-secondary" @click="hideCtrlSetting()">닫기</button>
                </template>
                <div class="row">
                    <div class="col-md-12 p-1" style="border: 1px solid #d5dbe0; border-radius: 5px;">
                        <div class="col-md-12 mt-1 pl-2 pr-2">
                            <span style="font-size: 13px; font-weight: bold;">제어 스케줄 설정</span>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">이벤트명 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <input v-model="schdEventName" type="text" class="form-control"
                                                placeholder="이벤트명">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설정 시간 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-model="schdHour" placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="kindEquip"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설비 유형 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-model="equipType" class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">
                                                    {{ type.equipType }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="ctrlMode"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">제어 모드 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-model="ctrlMode" class="form-control" id="ctrlMode"
                                                :disabled="equipType == ''" @change="showCtrlSetting(ctrlMode)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatType"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복 유형 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-model="repeatType" class="form-control" id="repeatType"
                                                @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatInterval"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복 시간
                                            : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-model="repeatInterval" class="form-control" id="repeatInterval">
                                                <option :value="null">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="endHour "
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복 종료 시간 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-model="endHour" placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="controlSetting" class="row mt-3"
                    style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                    <div class="col-md-12 mt-1 pl-2 pr-2">
                        <span style="font-size: 13px; font-weight: bold;">제어 설정</span>
                    </div>
                    <div class="col-md-12 p-1">
                        <!-- {{ setEquipCtrlProlList }} -->
                        <div class="col-md-12 p-1">
                            <div class="col-md-12">
                                <div v-for="(prop, index) in setEquipCtrlProlList" :key="index"
                                    class="form-group row align-items-center mb-1">
                                    <label for="runStatus"
                                        class="col-sm-3 col-form-label d-flex justify-content-center">
                                        {{ prop.propName }}
                                    </label>
                                    <div class="col-sm-8 pl-0 pr-0">
                                        <select v-if="prop.encodeType == 'Enum' && prop.encodeDesc"
                                            v-model="propValue[index]" class="form-control" id="runStatus">
                                            <option :value="null">선택</option>
                                            <option v-for="(item, key, idx) in JSON.parse(prop.encodeDesc)" :key="idx"
                                                :value="key">{{ item }}
                                            </option>
                                        </select>
                                        <input
                                            v-else-if="(prop.encodeType == 'Range' || prop.encodeType == 'Number') && prop.encodeDesc"
                                            type="number" class="form-control" :id="prop.propCode"
                                            v-model="propValue[index]" :min="JSON.parse(prop.encodeDesc).min"
                                            :max="JSON.parse(prop.encodeDesc).max"
                                            :step="JSON.parse(prop.encodeDesc).offset">
                                        <input v-else type="text" class="form-control" v-model="propValue[index]"
                                            :id="prop.propCode">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row mt-3 d-flex justify-content-center align-items-center"
                    style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                    <span style="font-size: 13px; font-weight: bold;">설비 유형과 제어 모드를 선택해주세요.</span>
                </div>
            </b-modal>
            <b-modal ref="editFixSchdSetting" centered id="editFixSchdSetting" hide-header @hidden="closeEditFixModal">
                <template #modal-footer="{ cancel }">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="setFixEditSchdSetting(scheSettingList[schdListIndex].equipType, schdListIndex)">저장</button>
                    <button type="button" class="btn btn-sm btn-secondary" @click="closeEditFixModal()">닫기</button>
                </template>
                <div class="row">
                    <div class="col-md-12 p-1" style="border: 1px solid #d5dbe0; border-radius: 5px;">
                        <div class="col-md-12 mt-1 pl-2 pr-2">
                            <span style="font-size: 13px; font-weight: bold;">제어 스케줄 설정</span>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">이벤트명 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <input v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.schdEventName"
                                                type="text" class="form-control" placeholder="이벤트명">
                                            <input v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.schdEventName"
                                                type="text" class="form-control" placeholder="이벤트명">
                                            <input v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.schdEventName"
                                                type="text" class="form-control" placeholder="이벤트명">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설정 시간 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.schdHour"
                                                placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.schdHour"
                                                placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.schdHour"
                                                placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="kindEquip"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설비 유형 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.equipType"
                                                class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(tempEhpSchdSetting.equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">{{ type.equipType }}</option>
                                            </select>
                                            <select v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.equipType"
                                                class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(tempLitSchdSetting.equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">{{ type.equipType }}</option>
                                            </select>
                                            <select v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.equipType"
                                                class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(tempKieMecsSetting.equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">{{ type.equipType }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="ctrlMode"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">제어 모드 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.ctrlMode"
                                                class="form-control" id="ctrlMode"
                                                :disabled="tempEhpSchdSetting.equipType == ''"
                                                @change="showCtrlSetting(tempEhpSchdSetting.ctrlMode)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                            <select v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.ctrlMode"
                                                class="form-control" id="ctrlMode"
                                                :disabled="tempLitSchdSetting.equipType == ''"
                                                @change="showCtrlSetting(tempEhpSchdSetting.ctrlMode)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                            <select v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.ctrlMode"
                                                class="form-control" id="ctrlMode"
                                                :disabled="tempKieMecsSetting.equipType == ''"
                                                @change="showCtrlSetting(tempEhpSchdSetting.ctrlMode)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatType"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복 유형
                                            : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.repeatType"
                                                class="form-control" id="repeatType" @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.repeatType"
                                                class="form-control" id="repeatType" @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.repeatType"
                                                class="form-control" id="repeatType" @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatInterval"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복
                                            시간 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting"
                                                v-model="tempEhpSchdSetting.repeatInterval" class="form-control"
                                                id="repeatInterval">
                                                <option :value="null">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempLitSchdSetting"
                                                v-model="tempLitSchdSetting.repeatInterval" class="form-control"
                                                id="repeatInterval">
                                                <option :value="null">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempKieMecsSetting"
                                                v-model="tempKieMecsSetting.repeatInterval" class="form-control"
                                                id="repeatInterval">
                                                <option :value="null">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="endHour "
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복 종료 시간
                                            : </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.endHour"
                                                placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.endHour"
                                                placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.endHour"
                                                placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="controlSetting" class="row mt-3"
                    style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                    <div class="col-md-12 mt-1 pl-2 pr-2">
                        <span style="font-size: 13px; font-weight: bold;">제어 설정</span>
                    </div>
                    <div v-if="setEquipCtrlProlList" class="col-md-12 p-1">
                        <div class="col-md-12 p-1">
                            <div class="col-md-12">
                                <div v-for="(prop, index) in setEquipCtrlProlList" :key="index"
                                    class="form-group row align-items-center mb-1">
                                    <label for="runStatus"
                                        class="col-sm-3 col-form-label d-flex justify-content-center">
                                        {{ prop.propName }}
                                    </label>
                                    <div class="col-sm-8 pl-0 pr-0">
                                        <select v-if="prop.encodeType == 'Enum' && prop.encodeDesc"
                                            v-model="propValue[index]" class="form-control" id="runStatus">
                                            <option :value="null">선택</option>
                                            <option v-for="(item, key, idx) in JSON.parse(prop.encodeDesc)" :key="idx"
                                                :value="key">{{ item }}
                                            </option>
                                        </select>
                                        <input
                                            v-else-if="(prop.encodeType == 'Range' || prop.encodeType == 'Number') && prop.encodeDesc"
                                            type="number" v-model="propValue[index]" class="form-control"
                                            :id="prop.propCode" :min="JSON.parse(prop.encodeDesc).min"
                                            :max="JSON.parse(prop.encodeDesc).max"
                                            :step="JSON.parse(prop.encodeDesc).offset">
                                        <input v-else type="text" class="form-control" v-model="propValue[index]"
                                            :id="prop.propCode">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row mt-3 d-flex justify-content-center align-items-center"
                    style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                    <span style="font-size: 13px; font-weight: bold;">설비 유형과 제어 모드를 선택해주세요.</span>
                </div>
            </b-modal>
        </div>
        <div v-else class="row">
            <form class="col-md-12">
                <div class="col-12 d-flex align-items-center p-0">
                    <div class="col-12 p-0 d-flex justify-content-end">
                        <button type="button" class="btn btn-warning btn-sm mr-1 p-0" style="width: 25px; height: 25px;"
                            @click="openSchdSettingModal()">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" style="width: 25px; height: 25px;"
                            @click="deleteSchdSetting()">-</button>
                    </div>
                </div>
            </form>
            <div class="col-md-12 pt-2" style="max-height: 250px; overflow-y: auto;">
                <table class="table table-bordered table-hover" style="text-align: center;">
                    <thead class="thead-dark">
                        <tr>
                            <th>{{ $t("선택") }}</th>
                            <th>{{ $t("이벤트 명") }}</th>
                            <th>{{ $t("제어 시간") }}</th>
                            <th>{{ $t("제어 유형") }}</th>
                            <th>{{ $t("제어 모드") }}</th>
                            <th>{{ $t("제어 설정") }}</th>
                            <th>{{ $t("변경일자") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in events" :key="index">
                            <th class="p-5">
                                <div class="form-check">
                                    <input v-model="schdSettingCheckList[index]" class="form-check-input"
                                        type="checkbox" id="kind1">
                                </div>
                            </th>
                            <td class="valign-middle" @click="addFixSchdSetting(index, events[index].equipType)">{{
                                item.schdEventName }}</td>
                            <td class="valign-middle" @click="addFixSchdSetting(index, events[index].equipType)">{{
                                item.schdHour }}</td>
                            <td class="text-center valign" @click="addFixSchdSetting(index, events[index].equipType)">{{
                                item.equipType }}</td>
                            <td class="text-center valign" @click="addFixSchdSetting(index, events[index].equipType)">{{
                                item.ctrlMode }}</td>
                            <td class="text-center valign" @click="addFixSchdSetting(index, events[index].equipType)">{{
                                item.ctrlCommand }}</td>
                            <td class="text-center valign" @click="addFixSchdSetting(index, events[index].equipType)">{{
                                item.modeDate }}</td>
                        </tr>
                        <tr v-if="events.length == 0">
                            <td colspan="7">표시할 데이터가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <b-modal ref="addSchdSetting" centered id="schdSetting" hide-header @hidden="hideCtrlSetting">
                <template #modal-footer>
                    <button type="button" class="btn btn-sm btn-primary" @click="setSchdSetting()">저장</button>
                    <button type="button" class="btn btn-sm btn-secondary" @click="hideCtrlSetting()">닫기</button>
                </template>
                <div class="row">
                    <div class="col-md-12 p-1" style="border: 1px solid #d5dbe0; border-radius: 5px;">
                        <div class="col-md-12 mt-1 pl-2 pr-2">
                            <span style="font-size: 13px; font-weight: bold;">제어 스케줄 설정</span>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">이벤트명
                                            : </label>
                                        <div class="col-sm-8 pl-0">
                                            <input v-model="schdEventName" type="text" class="form-control"
                                                placeholder="이벤트명">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설정 시간
                                            : </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-model="schdHour" placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="kindEquip"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설비
                                            유형 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-model="equipType" class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">{{ type.equipType }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="ctrlMode"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">제어
                                            모드 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-model="ctrlMode" class="form-control" id="ctrlMode"
                                                :disabled="equipType == ''" @change="showCtrlSetting(ctrlMode)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatType"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복
                                            유형 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-model="repeatType" class="form-control" id="repeatType"
                                                @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatInterval"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복 시간 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <select class="form-control" id="repeatInterval">
                                                <option :value="null">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="endHour "
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복
                                            종료 시간 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-model="endHour" placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="controlSetting" class="row mt-3"
                    style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                    <div class="col-md-12 mt-1 pl-2 pr-2">
                        <span style="font-size: 13px; font-weight: bold;">제어 설정</span>
                    </div>
                    <div v-if="setEquipCtrlProlList" class="col-md-12 p-1">
                        <div class="col-md-12 p-1">
                            <div class="col-md-12">
                                <div v-for="(prop, index) in setEquipCtrlProlList" :key="index"
                                    class="form-group row align-items-center mb-1">
                                    <label for="runStatus"
                                        class="col-sm-3 col-form-label d-flex justify-content-center">
                                        {{ prop.propName }}
                                    </label>
                                    <div class="col-sm-8 pl-0 pr-0">
                                        <select v-if="prop.encodeType == 'Enum' && prop.encodeDesc" class="form-control"
                                            id="runStatus" v-model="propValue[index]">
                                            <option :value="null">선택</option>
                                            <option v-for="(item, key, idx) in JSON.parse(prop.encodeDesc)" :key="idx"
                                                :value="key">{{ item }}
                                            </option>
                                        </select>
                                        <input
                                            v-else-if="(prop.encodeType == 'Range' || prop.encodeType == 'Number') && prop.encodeDesc"
                                            type="number" v-model="propValue[index]" class="form-control"
                                            :id="prop.propCode" :min="JSON.parse(prop.encodeDesc).min"
                                            :max="JSON.parse(prop.encodeDesc).max"
                                            :step="JSON.parse(prop.encodeDesc).offset">
                                        <input v-else type="text" class="form-control" v-model="propValue[index]"
                                            :id="prop.propCode">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row mt-3 d-flex justify-content-center align-items-center"
                    style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                    <span style="font-size: 13px; font-weight: bold;">설비 유형과 제어 모드를 선택해주세요.</span>
                </div>
            </b-modal>
            <b-modal ref="addFixSchdSetting" centered id="addFixSchdSetting" hide-header @hidden="closeAddFixModal">
                <template #modal-footer>
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="setFixAddSchdSetting(events[schdListIndex].equipType, schdListIndex)">저장</button>
                    <button type="button" class="btn btn-sm btn-secondary" @click="closeAddFixModal()">닫기</button>
                </template>
                <div class="row">
                    <div class="col-md-12 p-1" style="border: 1px solid #d5dbe0; border-radius: 5px;">
                        <div class="col-md-12 mt-1 pl-2 pr-2">
                            <span style="font-size: 13px; font-weight: bold;">제어 스케줄 설정</span>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">이벤트명
                                            : </label>
                                        <div class="col-sm-8 pl-0">
                                            <input v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.schdEventName"
                                                type="text" class="form-control" placeholder="이벤트명">
                                            <input v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.schdEventName"
                                                type="text" class="form-control" placeholder="이벤트명">
                                            <input v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.schdEventName"
                                                type="text" class="form-control" placeholder="이벤트명">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="setHour"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설정 시간
                                            : </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.schdHour"
                                                placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.schdHour"
                                                placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.schdHour"
                                                placeholder="설정 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="kindEquip"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">설비
                                            유형 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.equipType"
                                                class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(tempEhpSchdSetting.equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">{{ type.equipType }}</option>
                                            </select>
                                            <select v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.equipType"
                                                class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(tempLitSchdSetting.equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">{{ type.equipType }}</option>
                                            </select>
                                            <select v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.equipType"
                                                class="form-control" id="kindEquip"
                                                @change="getEquipCtrlList(tempKieMecsSetting.equipType)">
                                                <option value="">설비 유형 선택</option>
                                                <option v-for="(type) in equipTypeList" :key="type.equipType"
                                                    :value="type.equipType">{{ type.equipType }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="ctrlMode"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">제어
                                            모드 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.ctrlMode"
                                                class="form-control" id="ctrlMode"
                                                :disabled="tempEhpSchdSetting.equipType == ''"
                                                @change="showCtrlSetting(tempEhpSchdSetting.equipType)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                            <select v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.ctrlMode"
                                                class="form-control" id="ctrlMode"
                                                :disabled="tempLitSchdSetting.equipType == ''"
                                                @change="showCtrlSetting(tempLitSchdSetting.equipType)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                            <select v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.ctrlMode"
                                                class="form-control" id="ctrlMode"
                                                :disabled="tempKieMecsSetting.equipType == ''"
                                                @change="showCtrlSetting(tempKieMecsSetting.equipType)">
                                                <option value="">제어 모드 선택</option>
                                                <option v-for="(mode, i) in setEquipCtrlMode" :key="i"
                                                    :value="mode.ctrlMode">{{
                                                        mode.ctrlModeName }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 pl-2 pr-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatType"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복
                                            유형 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.repeatType"
                                                class="form-control" id="repeatType" @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.repeatType"
                                                class="form-control" id="repeatType" @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.repeatType"
                                                class="form-control" id="repeatType" @change="selectRepeatType">
                                                <option value="">선택</option>
                                                <option v-for="(repeat) in repeatTypeList" :key="repeat.key"
                                                    :value="repeat.key">
                                                    {{ repeat.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="repeatInterval"
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복 시간 :
                                        </label>
                                        <div class="col-sm-8 pl-0">
                                            <select v-if="tempEhpSchdSetting"
                                                v-model="tempEhpSchdSetting.repeatInterval" class="form-control"
                                                id="repeatInterval">
                                                <option value="">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempLitSchdSetting"
                                                v-model="tempLitSchdSetting.repeatInterval" class="form-control"
                                                id="repeatInterval">
                                                <option value="">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                            <select v-if="tempKieMecsSetting"
                                                v-model="tempKieMecsSetting.repeatInterval" class="form-control"
                                                id="repeatInterval">
                                                <option value="">선택</option>
                                                <option v-for="(interval) in intervalList" :key="interval.key"
                                                    :value="interval.key">
                                                    {{ interval.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row align-items-center mb-1">
                                        <label for="endHour "
                                            class="col-sm-4 col-form-label d-flex justify-content-center">반복
                                            종료 시간 : </label>
                                        <div class="col-sm-8 pl-0">
                                            <date-picker v-if="tempEhpSchdSetting" v-model="tempEhpSchdSetting.endHour"
                                                placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempLitSchdSetting" v-model="tempLitSchdSetting.endHour"
                                                placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                            <date-picker v-if="tempKieMecsSetting" v-model="tempKieMecsSetting.endHour"
                                                placeholder="반복 종료 시간" vertical
                                                :config="{ format: 'HH:mm', locale: 'ko' }"></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                    <div class="col-md-12 mt-1 pl-2 pr-2">
                        <span style="font-size: 13px; font-weight: bold;">제어 설정</span>
                    </div>
                    <div v-if="controlSetting" class="row mt-3"
                        style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                        <div class="col-md-12 mt-1 pl-2 pr-2">
                            <span style="font-size: 13px; font-weight: bold;">제어 설정</span>
                        </div>
                        <div class="col-md-12 p-1">
                            <!-- {{ setEquipCtrlProlList }} -->
                            <div class="col-md-12 p-1">
                                <div class="col-md-12">
                                    <div v-for="(prop, index) in setEquipCtrlProlList" :key="index"
                                        class="form-group row align-items-center mb-1">
                                        <label for="runStatus"
                                            class="col-sm-3 col-form-label d-flex justify-content-center">
                                            {{ prop.propName }}
                                        </label>
                                        <div class="col-sm-8 pl-0 pr-0">
                                            <select v-if="prop.encodeType == 'Enum' && prop.encodeDesc"
                                                v-model="propValue[index]" class="form-control" id="runStatus">
                                                <option :value="null">선택</option>
                                                <option v-for="(item, key, idx) in JSON.parse(prop.encodeDesc)" :key="idx"
                                                :value="key">{{ item }}
                                            </option>
                                            </select>
                                            <input
                                                v-else-if="(prop.encodeType == 'Range' || prop.encodeType == 'Number') && prop.encodeDesc"
                                                type="number" class="form-control" :id="prop.propCode"
                                                v-model="propValue[index]" :min="JSON.parse(prop.encodeDesc).min"
                                                :max="JSON.parse(prop.encodeDesc).max"
                                                :step="JSON.parse(prop.encodeDesc).offset">
                                            <input v-else type="text" class="form-control" v-model="propValue[index]"
                                                :id="prop.propCode">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row mt-3 d-flex justify-content-center align-items-center"
                        style="height: 250px; border: 1px solid #d5dbe0; border-radius: 5px;">
                        <span style="font-size: 13px; font-weight: bold;">설비 유형과 제어 모드를 선택해주세요.</span>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';
import moment from 'moment'

export default {
    props: {
        events: Array,
        ehpCheck: Boolean,
        litCheck: Boolean,
        kieMqtt: Boolean,
    },
    components: {},
    async created() {
        if (this.setScheduleDetail) {
            this.scheSettingList = this.setScheduleDetail.events;
            for (let i = 0; i < this.scheSettingList.length; i++) {
                this.scheSettingList[i].modDate = moment(this.scheSettingList[i].modDate).format("YYYY-MM-DD HH:mm:ss");
            }
        }
        // if(this.tempEhpSchdSetting) {
        //     await this.getEquipCtrlList(this.tempEhpSchdSetting.equipType);
        //     await this.showCtrlSetting(this.tempEhpSchdSetting.ctrlMode);
        // }
        // if(this.tempLitSchdSetting) {
        //     await this.getEquipCtrlList(this.tempLitpSchdSetting.equipType);
        //     await this.showCtrlSetting(this.tempLitSchdSetting.ctrlMode);
        // }
        await this.getEquipTypeList();
    },
    computed: {
        setScheduleDetail() {
            return this.$store.getters.SET_SCHEDULE_DETAIL;
        }
    },
    mounted() {
    },
    data() {
        return {
            setEquipCtrlMode: null,
            setEquipCtrlProlList: null,
            controlSetting: false,
            schdHour: "",
            equipType: "",
            ctrlMode: "",
            items: {
                selectEquipKind: "",
                SETTEMP: 0,
                ONOFF: null,
                MODE: "",
                FANSPEED: null
            },
            schdSettingCheckList: [],
            scheSettingList: null,
            schdEventName: "",
            schdListIndex: null,

            repeatType: "None",
            repeatInterval: null,
            endHour: null,

            repeatTypeList: [
                { key: "None", value: "미지정" },
                { key: "Min", value: "분" },
                { key: "Hour", value: "시간" }
            ],
            intervalList: null,

            // tempEhpSchdSetting: {
            //     schdEventName: "",
            //     schdHour: "",
            //     equipType: "",
            //     ctrlMode: "",
            //     ctrlCommand: {
            //         SETTEMP: 0,
            //         ONOFF: null,
            //         MODE: "",
            //         FANSPEED: null
            //     }
            // },
            // tempLitSchdSetting: {
            //     schdEventName: "",
            //     schdHour: "",
            //     equipType: "",
            //     ctrlMode: "",
            //     ctrlCommand: {
            //         ONOFF: null,
            //     }
            // },
            tempEhpSchdSetting: null,
            tempLitSchdSetting: null,
            tempKieMecsSetting: null,
            equipTypeList: [],
            getProperty: null,
            propValue: [],
        }
    },
    methods: {
        selectRepeatType() {
            if (this.repeatType) {
                if (this.repeatType == 'Min') {
                    this.intervalList = [
                        { key: 3, value: "3분" },
                        { key: 5, value: "5분" },
                        { key: 10, value: "10분" },
                        { key: 30, value: "30분" },
                    ]
                } else if (this.repeatType == 'Hour') {
                    this.intervalList = [
                        { key: 1, value: "1시간" },
                        { key: 2, value: "2시간" },
                        { key: 3, value: "3시간" },
                        { key: 4, value: "4시간" },
                        { key: 6, value: "6시간" },
                    ]
                } else {
                    this.intervalList = null;
                    this.repeatInterval = null;
                    this.endHour = null;
                }
            } else {
                if (this.tempEhpSchdSetting) {
                    if (this.tempEhpSchdSetting.repeatType == 'Min') {
                        this.intervalList = [
                            { key: 3, value: "3분" },
                            { key: 5, value: "5분" },
                            { key: 10, value: "10분" },
                            { key: 30, value: "30분" },
                        ]
                    } else if (this.tempEhpSchdSetting.repeatType == 'Hour') {
                        this.intervalList = [
                            { key: 1, value: "1시간" },
                            { key: 2, value: "2시간" },
                            { key: 3, value: "3시간" },
                            { key: 4, value: "4시간" },
                            { key: 6, value: "6시간" },
                        ]
                    } else {
                        this.intervalList = null;
                        this.this.tempEhpSchdSetting.repeatInterval = null;
                        this.this.tempEhpSchdSetting.endHour = null;
                    }
                }
                if (this.tempLitSchdSetting) {
                    if (this.tempLitSchdSetting.repeatType == 'Min') {
                        this.intervalList = [
                            { key: 3, value: "3분" },
                            { key: 5, value: "5분" },
                            { key: 10, value: "10분" },
                            { key: 30, value: "30분" },
                        ]
                    } else if (this.tempLitSchdSetting.repeatType == 'Hour') {
                        this.intervalList = [
                            { key: 1, value: "1시간" },
                            { key: 2, value: "2시간" },
                            { key: 3, value: "3시간" },
                            { key: 4, value: "4시간" },
                            { key: 6, value: "6시간" },
                        ]
                    } else {
                        this.intervalList = null;
                        this.this.tempLitSchdSetting.repeatInterval = null;
                        this.this.tempLitSchdSetting.endHour = null;
                    }
                }
                if (this.tempKieMecsSetting) {
                    if (this.tempKieMecsSetting.repeatType == 'Min') {
                        this.intervalList = [
                            { key: 3, value: "3분" },
                            { key: 5, value: "5분" },
                            { key: 10, value: "10분" },
                            { key: 30, value: "30분" },
                        ]
                    } else if (this.tempKieMecsSetting.repeatType == 'Hour') {
                        this.intervalList = [
                            { key: 1, value: "1시간" },
                            { key: 2, value: "2시간" },
                            { key: 3, value: "3시간" },
                            { key: 4, value: "4시간" },
                            { key: 6, value: "6시간" },
                        ]
                    } else {
                        this.intervalList = null;
                        this.this.tempKieMecsSetting.repeatInterval = null;
                        this.this.tempKieMecsSetting.endHour = null;
                    }
                }
            }
        },

        async getEquipTypeList() {
            try {
                let result = await backEndApi.equipTypes.getEquipTypeList();
                if (result.data) {
                    result.data.forEach(item => {
                        this.equipTypeList.push({ equipType: item.equipType })
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        async showCtrlSetting(ctrlMode) {
            // this.controlSetting = true;
            // this.setEquipCtrlProlList = null;
            // let ioProps;
            // let outProps = this.setEquipCtrlMode.find(item => item.ctrlMode == ctrlMode).outProps;
            // try {
            //     let result = await backEndApi.equipTypes.getEquipType("LGT");
            //     if(result.data) ioProps = result.data.ioProps;
            // } catch (e) {
            //     console.error(e)
            // }
            // let test = outProps.map(outProp => {
            //     return ioProps.find(ioProp => ioProp.propCode === outProp.propCode);
            // })
            // console.log(test);
            // this.setEquipCtrlProlList = this.setEquipCtrlMode.find(item => item.ctrlMode == ctrlMode);
            // console.log(this.setEquipCtrlProlList);'
            if (ctrlMode !== "") {
                this.controlSetting = true;
                this.setEquipCtrlProlList = this.getProperty;
            } else {
                this.controlSetting = false;
                this.setEquipCtrlProlList = null;
            }
        },
        hideCtrlSetting() {
            this.controlSetting = false;
            this.schdHour = "";
            this.equipType = "";
            this.ctrlMode = "";
            this.schdEventName = "";
            this.repeatType = null,
                this.repeatInterval = null,
                this.endHour = null,
                this.setEquipCtrlProlList = null;
            if (this.setScheduleDetail) this.$refs.editSchdSetting.hide();
            else this.$refs.addSchdSetting.hide();
        },
        filterEquipKind() {
            if (this.setScheduleDetail.events.length > 0) {
                if (this.items.selectEquipKind == "IDU") {
                    this.scheSettingList = null;
                    this.scheSettingList = this.setScheduleDetail.events.filter((v) => v.equipType == "IDU")
                } else if (this.items.selectEquipKind == "LGT") {
                    this.scheSettingList = null;
                    this.scheSettingList = this.setScheduleDetail.events.filter((v) => v.equipType == "LGT")
                } else if (this.items.selectEquipKind == "KIEMECS-CTRL") {
                    this.scheSettingList = null;
                    this.scheSettingList = this.setScheduleDetail.events.filter((v) => v.equipType == "KIEMECS-CTRL")
                }
                else {
                    this.scheSettingList = this.setScheduleDetail.events
                }
            }
        },
        async getEquipCtrlList(typeCode) {
            this.controlSetting = false;
            try {
                if (typeCode == "") {
                    this.ctrlMode = ""
                } else {
                    this.ctrlMode = ""
                    let result = await backEndApi.equipTypes.getEquipType(typeCode, "Y");
                    this.setEquipCtrlMode = result.data.ctrlModes;
                    this.getProperty = result.data.ioProps.filter(prop => prop.ioType === 'Both');
                    console.log("getEquipCtrlList getProperty", this.getProperty);
                }
            } catch (e) {
                console.error(e)
            }
        },
        setSchdSetting() {
            let ctrlCommand = {};
            if (this.equipType == "IDU") {

                // Object.keys(this.items).forEach(key => {
                //     if (this.items[key]) {
                //         ctrlCommand[key] = this.items[key];
                //     }
                // });

                this.setEquipCtrlProlList.forEach((prop, index) => {
                    ctrlCommand[prop.propCode] = this.propValue[index]
                })

                this.events.push({
                    schdEventName: this.schdEventName,
                    schdHour: this.schdHour,
                    equipType: this.equipType,
                    ctrlMode: this.ctrlMode,
                    repeatType: this.repeatType,
                    repeatInterval: this.repeatInterval,
                    endHour: this.endHour,
                    ctrlCommand: ctrlCommand,
                });
                this.schdEventName = "";
                this.controlSetting = false;
                this.schdHour = "";
                this.equipType = ""
                this.ctrlMode = "";
                this.repeatType = null,
                    this.repeatInterval = null,
                    this.endHour = null,
                    this.items = {
                        selectEquipKind: "",
                        ONOFF: null,
                        MODE: "",
                        FANSPEED: "",
                        SETTEMP: "",
                    }
                this.schdEventName = "";
                this.propValue = [];
                this.$refs.addSchdSetting.hide();
            }
            if (this.equipType == "LGT") {

                this.setEquipCtrlProlList.forEach((prop, index) => {
                    ctrlCommand[prop.propCode] = this.propValue[index] == '1' ? true : false
                })

                this.events.push({
                    schdEventName: this.schdEventName,
                    schdHour: this.schdHour,
                    equipType: this.equipType,
                    ctrlMode: this.ctrlMode,
                    repeatType: this.repeatType,
                    repeatInterval: this.repeatInterval,
                    endHour: this.endHour,
                    ctrlCommand: ctrlCommand
                });
                this.controlSetting = false;
                this.schdHour = "";
                this.equipType = ""
                this.ctrlMode = "";
                this.repeatType = null,
                    this.repeatInterval = null,
                    this.endHour = null,
                    this.items = {
                        selectEquipKind: "",
                        ONOFF: null,
                        MODE: "",
                        FANSPEED: "",
                        SETTEMP: "",
                    }
                this.propValue = [];
                this.$refs.addSchdSetting.hide();
            }
            if (this.equipType == "KIEMECS-CTRL") {

                this.setEquipCtrlProlList.forEach((prop, index) => {
                    ctrlCommand[prop.propCode] = this.propValue[index]
                })

                this.events.push({
                    schdEventName: this.schdEventName,
                    schdHour: this.schdHour,
                    equipType: this.equipType,
                    ctrlMode: this.ctrlMode,
                    repeatType: this.repeatType,
                    repeatInterval: this.repeatInterval,
                    endHour: this.endHour,
                    ctrlCommand: ctrlCommand
                });
                this.controlSetting = false;
                this.schdHour = "";
                this.equipType = ""
                this.ctrlMode = "";
                this.repeatType = null,
                    this.repeatInterval = null,
                    this.endHour = null,
                    this.items = {
                        selectEquipKind: "",
                        ONOFF: null,
                        MODE: "",
                        FANSPEED: "",
                        SETTEMP: "",
                    }
                this.propValue = [];
                this.$refs.addSchdSetting.hide();
            }
        },
        setEditSchdSetting() {
            console.log(this.propValue)
            console.log(this.setEquipCtrlProlList)
            let ctrlCommand = {};
            if (this.equipType == "IDU") {

                this.setEquipCtrlProlList.forEach((prop, index) => {
                    ctrlCommand[prop.propCode] = this.propValue[index]
                })

                this.setScheduleDetail.events.push({
                    schdEventName: this.schdEventName,
                    schdHour: this.schdHour,
                    equipType: this.equipType,
                    ctrlMode: this.ctrlMode,
                    repeatType: this.repeatType,
                    repeatInterval: this.repeatInterval,
                    endHour: this.endHour,
                    ctrlCommand: ctrlCommand,
                });
                this.controlSetting = false;
                this.schdHour = "";
                this.equipType = ""
                this.ctrlMode = "";
                this.repeatType = null,
                    this.repeatInterval = null,
                    this.endHour = null,
                    this.items = {
                        selectEquipKind: "",
                        ONOFF: null,
                        MODE: "",
                        FANSPEED: "",
                        SETTEMP: "",
                    }
                this.schdEventName = "";
                this.propValue = [];
                this.$refs.editSchdSetting.hide();
            }
            if (this.equipType == "LGT") {
                this.setEquipCtrlProlList.forEach((prop, index) => {
                    ctrlCommand[prop.propCode] = this.propValue[index] == '1' ? true : false
                })
                this.setScheduleDetail.events.push({
                    schdEventName: this.schdEventName,
                    schdHour: this.schdHour,
                    equipType: this.equipType,
                    ctrlMode: this.ctrlMode,
                    repeatType: this.repeatType,
                    repeatInterval: this.repeatInterval,
                    endHour: this.endHour,
                    ctrlCommand: ctrlCommand
                });
                this.controlSetting = false;
                this.schdHour = "";
                this.equipType = ""
                this.ctrlMode = "";
                this.items = {
                    selectEquipKind: "",
                    ONOFF: null,
                    MODE: "",
                    FANSPEED: "",
                    SETTEMP: "",
                }
                this.propValue = [];
                this.$refs.editSchdSetting.hide();
            }
            if (this.equipType == "KIEMECS-CTRL") {

                this.setEquipCtrlProlList.forEach((prop, index) => {
                    ctrlCommand[prop.propCode] = this.propValue[index]
                })

                this.setScheduleDetail.events.push({
                    schdEventName: this.schdEventName,
                    schdHour: this.schdHour,
                    equipType: this.equipType,
                    ctrlMode: this.ctrlMode,
                    repeatType: this.repeatType,
                    repeatInterval: this.repeatInterval,
                    endHour: this.endHour,
                    ctrlCommand: ctrlCommand,
                });
                this.controlSetting = false;
                this.schdHour = "";
                this.equipType = ""
                this.ctrlMode = "";
                this.repeatType = null,
                    this.repeatInterval = null,
                    this.endHour = null,
                    this.items = {
                        selectEquipKind: "",
                        ONOFF: null,
                        MODE: "",
                        FANSPEED: "",
                        SETTEMP: "",
                    }
                this.schdEventName = "";
                this.propValue = [];
                this.$refs.editSchdSetting.hide();
            }
        },
        deleteSchdSetting() {
            for (let i = this.schdSettingCheckList.length - 1; i >= 0; i--) {
                if (this.schdSettingCheckList[i]) {
                    this.events.splice(i, 1);
                }
            }
            this.schdSettingCheckList = [];
        },
        editDeleteSchdSetting() {
            for (let i = this.schdSettingCheckList.length - 1; i >= 0; i--) {
                if (this.schdSettingCheckList[i]) {
                    this.setScheduleDetail.events.splice(i, 1);
                }
            }
            this.schdSettingCheckList = [];
        },
        async editFixSchdSetting(index, typeCode) {
            this.schdListIndex = index;
            this.controlSetting = true;
            this.setEquipCtrlProlList = null;
            this.tempEhpSchdSetting = null;
            this.tempLitSchdSetting = null;
            this.tempKieMecsSetting = null;

            let result = await backEndApi.equipTypes.getEquipType(typeCode, "Y");
            this.setEquipCtrlMode = result.data.ctrlModes;
            this.setEquipCtrlProlList = result.data.ioProps.filter(prop => prop.ioType === 'Both');
            console.log("editFixSchdSetting setEquipCtrlProlList", this.setEquipCtrlProlList);
            if (typeCode == 'LGT') this.tempLitSchdSetting = { ...this.scheSettingList[index] };
            else if (typeCode == 'KIEMECS-CTRL') this.tempKieMecsSetting = { ...this.scheSettingList[index] };
            else this.tempEhpSchdSetting = { ...this.scheSettingList[index] };
            // this.setEquipCtrlProlList = this.setEquipCtrlMode.ctrlMode.find(item => item.ctrlMode == "Basic");
            // this.tempEhpSchdSetting = null;
            // this.tempLitSchdSetting = null;
            // this.tempLitSchdSetting = { ...this.scheSettingList[index] };
            // else {
            //     this.setEquipCtrlProlList = null;
            // }
            this.$refs.editFixSchdSetting.show();
        },
        async addFixSchdSetting(index, typeCode) {
            this.schdListIndex = index;
            this.controlSetting = true;

            let result = await backEndApi.equipTypes.getEquipType(typeCode, "Y");
            this.setEquipCtrlMode = result.data;
            this.setEquipCtrlProlList = result.data.ioProps.filter(prop => prop.ioType === 'Both');

            this.setEquipCtrlProlList = null;
            if (typeCode == "IDU") {
                // this.setEquipCtrlProlList = this.setEquipCtrlMode;
                this.tempEhpSchdSetting = null;
                this.tempLitSchdSetting = null;
                this.tempKieMecsSetting = null;
                this.tempEhpSchdSetting = { ...this.events[index] };
            } else if (typeCode == "LGT") {
                // this.setEquipCtrlProlList = this.setEquipCtrlMode;
                this.tempEhpSchdSetting = null;
                this.tempLitSchdSetting = null;
                this.tempKieMecsSetting = null;
                this.tempLitSchdSetting = { ...this.events[index] };
            } else if (typeCode == "KIEMECS-CTRL") {
                // this.setEquipCtrlProlList = this.setEquipCtrlMode;
                this.tempEhpSchdSetting = null;
                this.tempLitSchdSetting = null;
                this.tempKieMecsSetting = null;
                this.tempKieMecsSetting = { ...this.events[index] };
            }
            else {
                this.setEquipCtrlProlList = null;
            }
            this.$refs.addFixSchdSetting.show();
        },
        setFixEditSchdSetting(typeCode, index) {
            console.log(this.propValue);
            console.log(this.setEquipCtrlProlList);
            console.log(this.tempLitSchdSetting);
            if (typeCode == "IDU") {
                this.setEquipCtrlProlList.forEach((item, index) => {
                    this.tempEhpSchdSetting.ctrlCommand[item.propCode] = this.propValue[index]
                })
                this.scheSettingList[index] = { ...this.tempEhpSchdSetting };
            }
            if (typeCode == "LGT") {
                this.setEquipCtrlProlList.forEach((item, index) => {
                    this.tempLitSchdSetting.ctrlCommand[item.propCode] = this.propValue[index] == '1' ? true : false
                })
                this.scheSettingList[index] = { ...this.tempLitSchdSetting };
            }
            if (typeCode == "KIEMECS-CTRL") {
                this.setEquipCtrlProlList.forEach((item, index) => {
                    this.tempKieMecsSetting.ctrlCommand[item.propCode] = this.propValue[index]
                })
                this.scheSettingList[index] = { ...this.tempKieMecsSetting };
            }
            this.closeEditFixModal();
        },
        setFixAddSchdSetting(typeCode, index) {
            if (typeCode == "IDU") {
                this.setEquipCtrlProlList.forEach((item, index) => {
                    this.tempEhpSchdSetting.ctrlCommand[item.propCode] = this.propValue[index]
                })
                this.events[index] = { ...this.tempEhpSchdSetting };
            }
            if (typeCode == "LGT") {
                this.setEquipCtrlProlList.forEach((item, index) => {
                    this.tempLitSchdSetting.ctrlCommand[item.propCode] = this.propValue[index] == '1' ? true : false
                })
                this.events[index] = { ...this.tempLitSchdSetting };
            }
            if (typeCode == "KIEMECS-CTRL") {
                this.setEquipCtrlProlList.forEach((item, index) => {
                    this.tempKieMecsSetting.ctrlCommand[item.propCode] = this.propValue[index]
                })
                this.events[index] = { ...this.tempKieMecsSetting };
            }
            this.closeAddFixModal();
        },
        closeEditFixModal() {
            this.setEquipCtrlMode = null;
            this.propValue = [];
            this.repeatType = null,
                this.repeatInterval = null,
                this.endHour = null,
                // 모달을 닫을 때, 임시 변수를 초기화합니다.
                this.tempEhpSchdSetting = {
                    schdEventName: '',
                    schdHour: '',
                    equipType: '',
                    ctrlMode: '',
                    ctrlCommand: {
                        ONOFF: null,
                        MODE: '',
                        FANSPEED: '',
                        SETTEMP: null
                    },
                };
            this.tempLitSchdSetting = {
                schdEventName: '',
                schdHour: '',
                equipType: '',
                ctrlMode: '',
                ctrlCommand: {
                    ONOFF: null,
                    MODE: '',
                    FANSPEED: '',
                    SETTEMP: null
                },
            };
            this.tempKieMecsSetting = {
                schdEventName: '',
                schdHour: '',
                equipType: '',
                ctrlMode: '',
                ctrlCommand: {
                    ONOFF: null,
                    MODE: '',
                    FANSPEED: '',
                    SETTEMP: null
                },
            };
            // 수정 모달을 숨깁니다.
            this.$refs.editFixSchdSetting.hide();
        },
        closeAddFixModal() {
            this.setEquipCtrlMode = null;
            this.repeatType = null,
                this.repeatInterval = null,
                this.endHour = null,
                // 모달을 닫을 때, 임시 변수를 초기화합니다.
                this.tempEhpSchdSetting = {
                    schdEventName: '',
                    schdHour: '',
                    equipType: '',
                    ctrlMode: '',
                    ctrlCommand: {
                        ONOFF: null,
                        MODE: '',
                        FANSPEED: '',
                        SETTEMP: null
                    },
                };
            this.tempLitSchdSetting = {
                schdEventName: '',
                schdHour: '',
                equipType: '',
                ctrlMode: '',
                ctrlCommand: {
                    ONOFF: null,
                    MODE: '',
                    FANSPEED: '',
                    SETTEMP: null
                },
            };
            this.tempKieMecsSetting = {
                schdEventName: '',
                schdHour: '',
                equipType: '',
                ctrlMode: '',
                ctrlCommand: {
                    ONOFF: null,
                    MODE: '',
                    FANSPEED: '',
                    SETTEMP: null
                },
            };
            // 수정 모달을 숨깁니다.
            this.$refs.addFixSchdSetting.hide();
        },
        openSchdSettingModal() {
            if (this.setScheduleDetail) {
                this.$refs.editSchdSetting.show();
            } else {
                this.$refs.addSchdSetting.show();
            }
        }
    },
}
</script>

<style scoped></style>