<template>
    <div class="col-md-12 default" :style="{ overflow: 'hidden', minHeight: '500px' }">
        <div class="col-xl-12">
            <header-box :title="`${$t('건물 운영 관리')} > ${$t('스케줄 제어')}`" />
            <div class="row sch-header">
                <div class="col-lg-9">
                    <h4 class="panel-title valign-middle mb-0">스케줄 제어</h4>
                </div>
                <div class="col-lg-3">
                    <div class="btn-group pull-right">
                        <button type="button" class="btn btn-sm btn-danger mr-1"
                            @click="deleteSchdLists(schdListsIndex)">
                            <trans>삭제 처리</trans>
                        </button>
                        <button type="button" class="btn btn-sm btn-light mr-1">
                            <trans>사용 처리</trans>
                        </button>
                        <button type="button" class="btn btn-sm btn-light mr-1">
                            <trans>미사용 처리</trans>
                        </button>
                        <button type="button" class="btn btn-sm btn-warning" @click="addSchdModal()">
                            <trans>스케줄 추가</trans>
                        </button>
                    </div>
                    <vvo v-slot="v">
                        <b-modal ref="addSchdModal" centered hide-footer size="xl" title="스케줄 제어 설정(추가)" @hidden="closeAddSchedule()">
                            <template #modal-header>
                                <span>{{ '스케줄 제어 설정(추가)' }}</span>
                                <div>
                                    <button type="submit" class="btn btn-primary" style="margin-right: 5px;" @click="onValidate(v, saveSchedule(schdItem))">저장</button>
                                    <button type="button" class="btn btn-secondary" @click="closeAddSchedule()">취소</button>
                                </div>
                            </template>
                            <AddSchdModal :schdItem="schdItem"></AddSchdModal>
                        </b-modal>
                    </vvo>
                </div>
            </div>
            <div class="facilityMap-table-div">
                <table class="table table-hover bg-white">
                    <thead class="">
                        <tr>
                            <th class="text-center bg-inverse text-grey" style="width: 40px;">{{ $t("선택") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 70px;">{{ $t("일련번호") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 80px;">{{ $t("사용여부") }}</th>
                            <th class="text-center bg-inverse text-grey">{{ $t("스케줄 명") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 70px;">{{ $t("제어구역") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 130px;">{{ $t("제어 대상") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 130px;">{{ $t("제어 요일") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 220px;">{{ $t("제어 스케줄") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 170px;">{{ $t("스케줄 유효기간") }}</th>
                            <th class="text-center bg-inverse text-grey" style="width: 180px;">{{ $t("마지막 실행 시간") }}
                            </th>
                            <th class="text-center bg-inverse text-grey" style="width: 80px;">{{ $t("실행 횟수") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in scheduleDetails" :key="index">
                            <td class="text-center valign-middle">
                                <div class="form-group form-check d-flex justify-content-center align-items-center">
                                    <input v-model="schdListsCheck[index]" type="checkbox" class="form-check-input" @change="getSchdListsIndex(scheduleDetails[index].schdCtrlIdx, index)">
                                </div>
                            </td>
                            <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">{{ item.schdCtrlIdx ?? '-' }}</td>
                            <td class="text-center valign-middle">
                                <div class="custom-control custom-switch">
                                    <input v-model="schdListStatCheck[index]" type="checkbox" class="custom-control-input" :id="`state-switch${index}`" @change="setSchdCtrlEnabled(item.schdCtrlIdx, index)">
                                    <label class="custom-control-label" :for="`state-switch${index}`"></label>
                                </div>
                            </td>
                            <td class="valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">
                                <span style="font-weight: bold;">
                                    <p class="mb-1">{{ item.schdCtrlName ?? '-' }}</p>
                                    <p class="mb-0" style="color: #9ba6a5;">{{ item.description ?? '-' }}</p>
                                </span>
                            </td>
                            <!-- <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">{{ item.zoneName ?? '-' }}</td> -->
                            <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">{{ setZoneName(item.zoneIdx) }}</td>
                            <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">
                                <span v-html="setEquipList(item.equipTypes)"></span>
                            </td>
                            <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">{{ item.repeatConfig | koreanDay }}</td>
                            <td class="valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">
                                <span v-html="ctrlSchdSettingText(item.events)"></span>
                            </td>
                            <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">{{ `${item.startDate ?? '-'} ~ ${item.endDate ?? '-'}` }}</td>
                            <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">
                                <span v-html="setCtrlCount(item.lastCtrlDate, item.lastSuccessCnt, item.lastFailCnt)"></span>
                            </td>
                            <td class="text-center valign-middle" @click="fetchScheduleDetail(item.schdCtrlIdx)">{{ `${item.ctrlCnt ?? 0} 회` }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <b-modal ref="editSchdModal" id="conversion-modal" centered hide-footer size="xl">
                <template #modal-header="{ close }">
                    <span>{{ '스케줄 제어 설정(편집)' }}</span>
                    <div>
                        <button type="button" class="btn btn-danger" style="margin-right: 5px;"
                            @click="deleteSchedule(setScheduleDetail.schdCtrlIdx)">삭제</button>
                        <button type="button" class="btn btn-warning" style="margin-right: 5px;"
                            @click="setSchdCtrlLastEventRetry(setScheduleDetail.schdCtrlIdx)"
                            :disabled="setScheduleDetail.lastEventRetry == 'Y'">재실행</button>
                        <button type="button" class="btn btn-warning" style="margin-right: 5px;"
                            @click="reFetchScheduleDetail(setScheduleDetail.schdCtrlIdx)">갱신</button>
                        <button type="button" class="btn btn-primary" style="margin-right: 5px;"
                            @click="editSchedule(setScheduleDetail)">저장</button>
                        <button type="button" class="btn btn-secondary" @click="close">취소</button>
                    </div>
                </template>
                <SchdModal :schdItem="schdItem"></SchdModal>
            </b-modal>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import headerBox from "@views/component/headerBox/analysis/Header.vue";
import SchdModal from "@views/equip-control/schedule-control/ScheduleModal.vue"
import AddSchdModal from "./AddScheduleModal.vue";
import moment from 'moment'

export default {
    components: {
        headerBox,
        SchdModal,
        AddSchdModal,
    },
    filters: {
        koreanDay(repeatConfig) {
            if (!repeatConfig) return '';
            const days = Object.keys(repeatConfig).filter(day => repeatConfig[day]);
            const koreanDays = {
                "Mon": "월",
                "Tue": "화",
                "Wed": "수",
                "Thu": "목",
                "Fri": "금",
                "Sat": "토",
                "Sun": "일"
            };
            return days.map(day => koreanDays[day]).join('/');
        }
    },
    data() {
        return {
            schdItem: {
                schdCtrlName: "",
                zoneIdx: null,
                repeatConfig: {
                    Mon: false,
                    Tue: false,
                    Wed: false,
                    Thu: false,
                    Fri: false,
                    Sat: false,
                    Sun: false,
                },
                exceptConfig: [],
                description: "",
                startDate: "",
                endDate: "",
                targets: [],
                groups: [],
                equips: [],
                stat: null,
                events: [],
            },
            ehpCheck: false,
            litCheck: false,
            schdListsIndex: null,
            schdListsCheck: [],
            schdListStatCheck: [],
            zoneName: null,
        };
    },
    computed: {
        scheduleDetails() {
            return this.$store.getters.GET_SCHEDULE_DETAILS;
        },
        setScheduleDetail() {
            return this.$store.getters.SET_SCHEDULE_DETAIL;
        }
    },
    mounted() {
    },
    async created() {
        await this.fetchScheduleDetails();
    },
    methods: {
        baseInit() {
            this.schdItem = {
                schdCtrlName: "",
                zoneIdx: null,
                repeatConfig: {
                    Mon: false,
                    Tue: false,
                    Wed: false,
                    Thu: false,
                    Fri: false,
                    Sat: false,
                    Sun: false,
                },
                exceptConfig: [],
                description: "",
                startDate: null,
                endDate: null,
                targets: [],
                groups: [],
                equips: [],
                stat: null,
                events: [],
            }
        },
        async fetchScheduleDetails() {
            await this.$store.dispatch('FETCH_SCHEDULE_DETAILS');
            if (this.scheduleDetails) {
                for (let i = 0; i < this.scheduleDetails.length; i++) {
                    this.scheduleDetails[i].startDate = this.scheduleDetails[i].startDate ? moment(this.scheduleDetails[i].startDate).format("YYYY-MM-DD") : null
                    this.scheduleDetails[i].endDate = this.scheduleDetails[i].endDate ? moment(this.scheduleDetails[i].endDate).format("YYYY-MM-DD") : null;
                    this.scheduleDetails[i].lastCtrlDate = this.scheduleDetails[i].lastCtrlDate ? moment(this.scheduleDetails[i].lastCtrlDate).format("YYYY-MM-DD HH:mm:ss") : "";
                    if (this.scheduleDetails[i].enabled == "Y") {
                        this.schdListStatCheck.push(true);
                    } else if (this.scheduleDetails[i].enabled == "N") {
                        this.schdListStatCheck.push(false);
                    } else {
                        this.schdListStatCheck = [];
                    }
                }
            }
        },
        async fetchScheduleDetail(index) {
            await this.$store.dispatch('FETCH_SCHEDULE_DETAIL', index);
            this.$refs.editSchdModal.show();
        },
        async reFetchScheduleDetail(index) {
            await this.$store.dispatch('FETCH_SCHEDULE_DETAIL', index);
            await this.alertNoti("스케줄 내용이 갱신되었습니다.");
        },
        async saveSchedule(addItem) {
            // let startDate;
            // let endDate;
            // if (addItem.startDate !== "") {
            //     startDate = new Date(addItem.startDate)
            //     addItem.startDate = moment(startDate).format();
            // } else {
            //     addItem.startDate = null
            // }
            // if (addItem.endDate !== "") {
            //     endDate = new Date(addItem.endDate)
            //     addItem.endDate = moment(endDate).format();
            // } else {
            //     addItem.endDate = null
            // }
            try {
                let filteredItem = {};
                if (!addItem.targets.length > 0) {
                    await this.alertNoti("설비 유형을 선택해주세요.")
                    return;
                }
                if (!addItem.equips.length > 0 && !addItem.groups.length > 0) {
                    await this.alertNoti("선택한 설비 유형의 설비 그룹 또는 개별 설비를 선택해주세요.")
                    return;
                }
                if (!addItem.events.length > 0) {
                    await this.alertNoti("제어 스케줄을 설정해주세요.")
                    return;
                }
                addItem.exceptConfig.forEach(item => {
                    delete item.checked;
                    delete item.toggled;
                });
                this.$store.state.commonCodes.weekDay.forEach((item) => {
                    if(addItem.repeatConfig[item.value]) addItem.repeatConfig[item.value] = "Y";
                    else delete addItem.repeatConfig[item.value]
                })
                for (const key in addItem) {
                    if (addItem.hasOwnProperty(key) && addItem[key] !== null) {
                        filteredItem[key] = addItem[key];
                    }
                }
                let result = await backEndApi.equipControl.saveEquipCtrlSch(filteredItem);
                if (result.data) {
                    await this.alertNoti("저장되었습니다.");
                    await this.fetchScheduleDetails();
                    this.schdListsCheck = [];
                    this.$refs.addSchdModal.hide();
                    this.baseInit();
                }
            } catch (e) {
                await this.alertNoti("저장에 실패하였습니다.")
                console.error(e);
            }
        },
        closeAddSchedule() {
            this.ehpCheck = false,
                this.litCheck = false,
                this.$refs.addSchdModal.hide();
                this.baseInit();
        },
        async editSchedule(editItem) {
            editItem.exceptConfig.forEach(item => {
                delete item.checked;
                delete item.toggled;
            });
            this.$store.state.commonCodes.weekDay.forEach((item) => {
                if(editItem.repeatConfig[item.value]) editItem.repeatConfig[item.value] = "Y"
                else delete editItem.repeatConfig[item.value]
            })
            editItem.startDate = moment(editItem.startDate).format();
            editItem.endDate = moment(editItem.endDate).format();
            try {
                if (!editItem.targets.length > 0) {
                    await this.alertNoti("설비 유형을 선택해주세요.")
                    return;
                }
                if (!editItem.equips.length > 0 && !editItem.groups.length > 0) {
                    await this.alertNoti("선택한 설비 유형의 설비 그룹 또는 개별 설비를 선택해주세요.")
                    return;
                }
                if (!editItem.events.length > 0) {
                    await this.alertNoti("제어 스케줄을 설정해주세요.")
                    return;
                }
                let result = await backEndApi.equipControl.saveEquipCtrlSch(editItem);
                if (result.data) {
                    await this.alertNoti("변경 내용이 저장되었습니다.");
                    await this.fetchScheduleDetails();
                    this.$refs.editSchdModal.hide();
                }
            } catch (e) {
                await this.alertNoti("변경 내용 저장에 실패하였습니다.")
                console.error(e);
            }
        },
        async deleteSchedule(index) {
            try {
                const confirm = await this.alertConfirmWarning("스케줄을 삭제하시겠습니까?");
                if (!confirm.value) return;
                else {
                    let result = await backEndApi.equipControl.deleteEquipCtrlSch(index);
                    if (result.data) {
                        await this.alertNoti("스케줄이 삭제되었습니다.");
                        await this.fetchScheduleDetails();
                        this.$refs.editSchdModal.hide();
                    } else {
                        this.alertNoti("스케줄 삭제에 실패하였습니다.");
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },
        addSchdModal() {
            this.$store.commit('SET_SCHEDULE_DETAIL', null);
            this.$refs.addSchdModal.show();
        },
        setEquipList(equipList) {
            return `에어컨(${equipList.filter((v) => v.equipType == "IDU").length}) <br>조명(${equipList.filter((v) => v.equipType == "LGT").length}) <br>KIEMECS(${equipList.filter((v) => v.equipType == "KIEMECS-CTRL").length})`
        },
        async setAllActive() {
            const result = await this.alertConfirmWarning("모든 스케줄을 활성화하시겠습니까?");
            if (!result.value) return;
        },
        async setAllInActive() {
            const result = await this.alertConfirmWarning("모든 스케줄을 비활성화하시겠습니까?");
            if (!result.value) return;
        },
        setCtrlCount(lastCtrlDate, lastSuccessCnt, lastFailCnt) {
            return `<p style="margin-bottom: 0px !important;">${lastCtrlDate}</p><span style="font-weight: bold; color: #2772db;">성공: ${lastSuccessCnt ?? 0} </span><span style="font-weight: bold; color: #ef5a5a;">실패: ${lastFailCnt ?? 0}</span>`
        },
        ctrlSchdSettingText(events) {
            let html = '';
            if (events.length > 0) {
                for (let i = 0; i < events.length; i++) {
                    html += `<div style="padding-bottom: ${i === events.length - 1 ? '0' : '5px'};"><span style="color: #fff; background-color: #97cba9; padding: 3px; border-radius: 3px; font-weight: bold;">${events[i].schdHour}</span> ${events[i].schdEventName}</div>`;
                }
            }
            return html;
        },
        ctrlTimeText(startDate, endDate) {
            let start = moment(startDate).format("YYYY-MM-DD")
            let end = moment(endDate).for("YYYY-MM-DD")
            return `${start} ~ ${end}`
        },
        async setSchdCtrlEnabled(schdCtrlIdx, index) {
            if (this.schdListStatCheck[index] == false) {
                try {
                    await backEndApi.equipControl.setSchdCtrlEnabled({ schdCtrlIdx: schdCtrlIdx, enabled: "N" })
                    await this.alertNoti("스케줄을 작동을 중지하였습니다.");
                    await this.fetchScheduleDetails()
                } catch (e) {
                    console.error(e)
                }
            } else if (this.schdListStatCheck[index] == true) {
                try {
                    await backEndApi.equipControl.setSchdCtrlEnabled({ schdCtrlIdx: schdCtrlIdx, enabled: "Y" })
                    await this.alertNoti("스케줄을 작동하였습니다.")
                    await this.fetchScheduleDetails()
                } catch (e) {
                    console.error(e)
                }
            }
        },
        async setSchdCtrlLastEventRetry(schdCtrlIdx) {
            let retryItem = {
                schdCtrlIdx: schdCtrlIdx,
                lastEventRetry: "Y"
            }
            try {
                let result = await backEndApi.equipControl.setSchdCtrlLastEventRetry(retryItem);
                if (result.data) {
                    await this.alertNoti("스케줄이 재실행 되었습니다.");
                    await this.$store.dispatch('FETCH_SCHEDULE_DETAIL', schdCtrlIdx);
                    await this.fetchScheduleDetails();
                } else {
                    this.alertNoti("스케줄 재실행에 실패하였습니다.")
                }
            } catch (e) {
                console.error(e)
            }
        },
        getSchdListsIndex(schdIndex, index) {
            this.schdListsIndex = null;
            if (this.schdListsCheck[index] == true) {
                this.schdListsIndex = schdIndex;
            } else {
                this.schdListsIndex = null;
            }
        },
        async deleteSchdLists(index) {
            if (index) {
                const confirm = await this.alertConfirmWarning("스케줄을 삭제하시겠습니까?");
                if (!confirm.value) return;
                else {
                    let result = await backEndApi.equipControl.deleteEquipCtrlSch(index);
                    if (result.data) {
                        await this.alertNoti("스케줄이 삭제되었습니다.");
                        await this.fetchScheduleDetails();
                        this.schdListsCheck = [];
                    } else {
                        this.alertNoti("스케줄 삭제에 실패하였습니다.");
                    }
                }
            } else {
                this.alertNoti("스케줄 삭제에 실패하였습니다.");
            }
        },
        setZoneName(zoneIdx) {
            return this.zoneName = this.$store.state.zoneInfo.find(item => item.value == zoneIdx).text ?? "-";
        }
    }
}
</script>

<style scoped>
.sch-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000010;
    height: 50px;
    margin: 10px 0px 0px 0px;
}
</style>