<template>
    <div style="margin-bottom: 10px;">
        <p v-b-toggle.schdSetting class="col-12 schd-setting-list">
            <!-- <span>{{ toggleStatus ? '스케줄 제어 정보' : `스케줄 제어 정보 (${schdDetailItem.schdCtrlName})` }}</span> -->
            <span>스케줄 제어 정보</span>
            <span class="when-opened">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
            <span class="when-closed">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
            </span>
        </p>
        <b-collapse visible id="schdSetting"
            style="border: 1px solid #d5dbe0; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
            <div class="row">
                <div class="col-md-7 mt-2">
                    <div class="form-group row align-items-center mb-1">
                        <label for="name" class="col-sm-3 col-form-label d-flex justify-content-center">스케줄명 :</label>
                        <div class="col-sm-9">
                            <!-- <input v-model="schdItem.schdCtrlName" type="text" class="form-control" id="name" placeholder="스케줄명"> -->
                            <valid-input
                                :vid="$t('스케줄명')"
                                :placeholder="'스케줄명'"
                                :inputType="'text'"
                                :inputValue.sync="schdItem.schdCtrlName"
                                :rules="rules.CLBRN_HISTORY_RULE"
                                :errorMessage="'스케줄명 입력은 필수입니다.'"
                            ></valid-input>
                        </div>
                    </div>
                    <div class="form-group row align-items-center mb-1">
                        <label for="select-date" class="col-sm-3 col-form-label d-flex justify-content-center">요일선택 :</label>
                        <div id="select-date" class=" col-sm-9 form-group row align-items-center justify-content-center mb-0 pr-0" style="padding-left: 18px;">
                            <div class="col-sm-12 d-flex align-items-center justify-content-around"
                                style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 7px;">
                                <div v-for="(day, i) in day" :key="i" class="form-check mr-1">
                                    <input v-model="schdItem.repeatConfig[day.key]" class="form-check-input" type="checkbox" :id="day.key">
                                    <label class="form-check-label" :for="day.key">{{ day.value }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-center mb-1">
                        <label for="date" class="col-sm-3 col-form-label d-flex justify-content-center">적용기간 :</label>
                        <div class="col-sm-9 d-flex align-items-center justify-content-center" id="date">
                            <date-picker v-model="schdItem.startDate" class="form-control col-sm-5" placeholder="시작일" vertical :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }"></date-picker>
                            <span class="col-sm-2 d-flex justify-content-center" style="padding: 0px 5px 0px 5px;">~</span>
                            <date-picker v-model="schdItem.endDate" class="form-control col-sm-5" placeholder="종료일" vertical :config="{ format: 'YYYY-MM-DD', locale: 'ko', viewMode: 'days' }"></date-picker>
                        </div>
                    </div>
                    <div class="form-group row align-items-center mb-1">
                        <label for="schdDesc" class="col-sm-3 col-form-label d-flex justify-content-center">스케줄 설명 :</label>
                        <div class="col-sm-9">
                            <textarea v-model="schdItem.description" class="form-control" id="schdDesc" style="height: 80px; resize: none; overflow-y: auto;"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 mt-2 pr-4">
                    <div class="form-group row">
                        <div class="col-sm-12 d-flex mb-3 align-items-center">
                            <label class="col-sm-3 col-form-label p-0" for="zone">적용구역 : </label>
                            <select v-model="schdItem.zoneIdx" class="form-control" id="zone">
                                <option :value="null">적용구역선택</option>
                                <option v-for="(zone, i) in zoneInfo" :key="i" :value="zone.zoneIdx">{{ zone.zoneName }}</option>
                            </select>
                        </div>
                        <div class="col-sm-12">
                            <label class="col-form-label" for="ctrlKind">설비 유형</label>
                            <div id="ctrlKind">
                                <div style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px;">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="ehpi" v-model="ehpCheck" @change="targetAddEhp()">
                                        <label class="form-check-label" for="ehpi">에어컨</label>
                                    </div>
                                    <div class="form-check mt-1">
                                        <input class="form-check-input" type="checkbox" id="lit" v-model="litCheck" @change="targetAddLit()">
                                        <label class="form-check-label" for="lit">조명</label>
                                    </div>
                                    <div class="form-check mt-1">
                                        <input class="form-check-input" type="checkbox" id="kieMecs" v-model="kieMecs" @change="targetAddKieMecs()">
                                        <label class="form-check-label" for="kieMecs">KIEMECS</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
        <div class="mt-3">
            <b-card no-body>
                <b-tabs card>
                    <b-tab v-if="ehpCheck" title="에어컨 제어 대상">
                        <EhpTab :equipGroup="schdItem.groups" :equip="schdItem.equips" :zoneInfo="zoneInfo"></EhpTab>
                    </b-tab>
                    <b-tab v-if="litCheck" title="조명 제어 대상">
                        <LitTab :equipGroup="schdItem.groups" :equip="schdItem.equips" :zoneInfo="zoneInfo"></LitTab>
                    </b-tab>
                    <b-tab v-if="kieMecs" title="KIEMECS 제어 대상">
                        <KieMqttTab :equipGroup="schdItem.groups" :equip="schdItem.equips" :zoneInfo="zoneInfo"></KieMqttTab>
                    </b-tab>
                    <b-tab title="스케줄 설정">
                        <SchdSettingTab :ehpCheck="ehpCheck" :litCheck="litCheck" :kieMecs="kieMecs" :events="schdItem.events"></SchdSettingTab>
                    </b-tab>
                    <b-tab title="예외일 설정">
                        <ExceptionDayTab :exceptConfig="schdItem.exceptConfig"></ExceptionDayTab>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import EhpTab from "./tab/EhpTab.vue"
import LitTab from "./tab/LitTab.vue"
import KieMqttTab from "./tab/KieMqttTab.vue"
import SchdSettingTab from "./tab/SchdSettingTab.vue"
import ExceptionDayTab from "./tab/ExceptionDayTab.vue"
import ValidInput from "@src/components/validate-input/Valid-Input.vue";
import * as rules from "@src/consts/ruleConsts.js";

export default {
    props: {
        schdItem: Object,
    },
    components: {
        EhpTab,
        LitTab,
        KieMqttTab,
        SchdSettingTab,
        ExceptionDayTab,
        ValidInput,
        // MonitoringTab,
    },
    computed: {
        setScheduleDetail() {
            return this.$store.getters.SET_SCHEDULE_DETAIL;
        }
    },
    created() {
        this.getZoneInfo();
    },
    data() {
        return {
            zoneInfo: [],
            ehpCheck: false,
            litCheck: false,
            kieMecs: false,
            day: [
                { key: "Mon", value: "월"},
                { key: "Tue", value: "화"},
                { key: "Wed", value: "수"},
                { key: "Thu", value: "목"},
                { key: "Fri", value: "금"},
                { key: "Sat", value: "토"},
                { key: "Sun", value: "일"},
            ],
            rules,
        }
    },
    methods: {
        targetAddEhp() {
            if (this.ehpCheck) {
                console.log("ehpcheck true")
                // 에어컨 체크가 true일 때
                if (!this.schdItem.targets.some(item => item.equipType === 'IDU')) {
                    console.log("ehp add")
                    // 이미 배열에 해당 항목이 없다면 추가
                    this.schdItem.targets.push({
                        equipType: "IDU"
                    });
                    this.alertNoti("에어컨 제어를 사용합니다. 아래의 에어컨 제어 탭에서 제어 설비를 선택할 수 있습니다.");
                }
            } else {
                console.log("ehp delete")
                // 에어컨 체크가 false일 때
                this.schdItem.targets = this.schdItem.targets.filter(item => item.equipType !== 'IDU');
                this.alertNoti("에어컨 제어를 사용하지 않습니다.");
            }
        },
        targetAddLit() {
            if (this.litCheck) {
                console.log("lit check true")
                // 조명 체크가 true일 때
                if (!this.schdItem.targets.some(item => item.equipType === 'LGT')) {
                    console.log("lit add")
                    // 이미 배열에 해당 항목이 없다면 추가
                    this.schdItem.targets.push({
                        equipType: "LGT"
                    });
                    this.alertNoti("조명 제어를 사용합니다. 아래의 조명 제어 탭에서 제어 설비를 선택할 수 있습니다.");
                }
            } else {
                console.log("lit delete")
                // 조명 체크가 false일 때
                this.schdItem.targets = this.schdItem.targets.filter(item => item.equipType !== 'LGT');
                this.alertNoti("조명 제어를 사용하지 않습니다.");
            }
        },
        targetAddKieMecs() {
            if (this.kieMecs) {
                // 조명 체크가 true일 때
                if (!this.schdItem.targets.some(item => item.equipType === 'LGT')) {
                    // 이미 배열에 해당 항목이 없다면 추가
                    this.schdItem.targets.push({
                        equipType: "KIEMECS-CTRL"
                    });
                    this.alertNoti("KIEMECS 제어를 사용합니다. 아래의 KIEMECS 제어 탭에서 제어 설비를 선택할 수 있습니다.");
                }
            } else {
                // 조명 체크가 false일 때
                this.schdItem.targets = this.schdItem.targets.filter(item => item.equipType !== 'KIEMECS-CTRL');
                this.alertNoti("KIEMECS 제어를 사용하지 않습니다.");
            }
        },
        async getZoneInfo() {
            let result = await backEndApi.zoneInfo.getZoneInfoList();
            result.data.forEach((v) => this.zoneInfo.push(v))
        },
    },
}
</script>

<style scoped>
.schd-setting-list {
    background-color: #f59c1a;
    padding: 10px;
    margin-bottom: 0px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

.table-wrapper {
    overflow-x: auto;
}

.table-body-wrapper {
    overflow-y: auto;
    max-height: 300px;
    /* 테이블 바디의 최대 높이를 조절하세요 */
}</style>