<template>
    <div>
        <div class="row" v-if="setScheduleDetail">
            <form action="" class="col-md-6">
                <div class="col-12 d-flex align-items-center p-0">
                    <!-- <span v-if="setScheduleDetail.group" class="col-4 p-0" style="font-size: 12px; font-weight: bold;">{{ `설비그룹(${setScheduleDetail.group.length})` }}</span> -->
                    <span class="col-4 p-0" style="font-size: 12px; font-weight: bold;">{{ `설비그룹` }}</span>
                    <div class="col-8 p-0 d-flex justify-content-end">
                        <button type="button" class="btn btn-warning btn-sm mr-1 p-0"
                            style="width: 25px; height: 25px;" @click="openEhpGroupModal()">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" style="width: 25px; height: 25px;"
                            @click="editDeleteEquipGroup()">-</button>
                    </div>
                </div>
                <div class="col-12 p-0 mt-2">
                    <div
                        style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px; height: 150px; max-height: 150px; overflow-y: auto;">
                        <div v-for="(grp, i) in setScheduleDetail.groups" :key="i" class="form-check  mt-1">
                            <div v-if="grp.equipTypeCode == 'EHPI'">
                                <input v-model="setEquipGrpCheckList[i]" class="form-check-input" type="checkbox"
                                    :id="`editEhpGroupList${i}`">
                                <label v-if="equipAllGroupList" class="form-check-label" :for="`editEhpGroupList${i}`">{{ equipAllGroupList.find((v) => v.equipGrpIdx
                                    == grp.equipGrpIdx).equipGrpName }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form action="" class="col-md-6">
                <div class="col-12 d-flex align-items-center p-0">
                    <span class="col-4 p-0" style="font-size: 12px; font-weight: bold;">개별설비</span>
                    <div class="col-8 p-0 d-flex justify-content-end">
                        <button type="button" class="btn btn-warning btn-sm mr-1 p-0" style="width: 25px; height: 25px;" @click="openEhpModal()">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" style="width: 25px; height: 25px;" @click="editDeleteEquip()">-</button>
                    </div>
                </div>
                <div class="col-12 p-0 mt-2">
                    <div
                        style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px; height:150px; max-height: 150px; overflow-y: auto;">
                        <div v-for="(eqp, i) in setScheduleDetail.equips" :key="i" class="form-check">
                            <div v-if="eqp.equipType == 'IDU'">
                                <input v-model="setEquipCheckList[i]" class="form-check-input" type="checkbox" :id="`editEhpEquipList${i}`">
                                <label v-if="equipAllList" class="form-check-label" :for="`editEhpEquipList${i}`">{{ `${equipAllList.find((v) => v.equipIdx ==
                                    eqp.equipIdx).equipName}` }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row" v-else>
            <form action="" class="col-md-6">
                <div class="col-12 d-flex align-items-center p-0">
                    <span class="col-4 p-0" style="font-size: 12px; font-weight: bold;">{{ `설비그룹` }}</span>
                    <div class="col-8 p-0 d-flex justify-content-end">
                        <button type="button" class="btn btn-warning btn-sm mr-1 p-0"
                            style="width: 25px; height: 25px;" @click="openEhpGroupModal()">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" style="width: 25px; height: 25px;"
                            @click="deleteEquipGroup()">-</button>
                    </div>
                </div>
                <div class="col-12 p-0 mt-2">
                    <div
                        style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px; height: 150px; max-height: 150px; overflow-y: auto;">
                        <div v-for="(grp, i) in equipGroup" :key="i" class="form-check  mt-1">
                            <div v-if="grp.equipTypeCode == 'EHPI'">
                                <input v-model="setEquipGrpCheckList[i]" class="form-check-input" type="checkbox"
                                    :id="`addEhpGroupList${i}`">
                                <label v-if="equipAllGroupList" class="form-check-label" :for="`addEhpGroupList${i}`">{{ equipAllGroupList.find((v) => v.equipGrpIdx
                                    == grp.equipGrpIdx).equipGrpName }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form action="" class="col-md-6">
                <div class="col-12 d-flex align-items-center p-0">
                    <span class="col-4 p-0" style="font-size: 12px; font-weight: bold;">{{ `개별설비` }}</span>
                    <div class="col-8 p-0 d-flex justify-content-end">
                        <button type="button" class="btn btn-warning btn-sm mr-1 p-0" style="width: 25px; height: 25px;" @click="openEhpModal()">+</button>
                        <button type="button" class="btn btn-danger btn-sm p-0" style="width: 25px; height: 25px;" @click="deleteEquip()">-</button>
                    </div>
                </div>
                <div class="col-12 p-0 mt-2">
                    <div
                        style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px; height:150px; max-height: 150px; overflow-y: auto;">
                        <div v-for="(eqp, i) in equip" :key="i" class="form-check">
                            <div v-if="eqp.equipType == 'IDU'">
                                <input v-model="setEquipCheckList[i]" class="form-check-input" type="checkbox" :id="`addEhpEquipList${i}`">
                                <label v-if="equipAllList" class="form-check-label" :for="`addEhpEquipList${i}`">{{ `${equipAllList.find((v) => v.equipIdx ==
                                    eqp.equipIdx).equipName}` }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <b-modal centered ref="ehpGroupModal" id="ehpGroupModal">
            <template #modal-header>
                <span style="font-size: 14px; font-weight: bold;">설비 그룹 선택</span>
            </template>
            <template #modal-footer>
                <button v-if="setScheduleDetail" type="button" class="btn btn-sm btn-primary" @click="setEditEquipGroupList()">선택</button>
                <button v-else type="button" class="btn btn-sm btn-primary" @click="setEquipGroupList()">선택</button>
                <button type="button" class="btn btn-sm btn-secondary" @click="closeEquipGroupModal()">닫기</button>
            </template>
            <form class="form-group row align-items-center">
                <div class="col-6">
                    <select v-model="equipGrpType" class="form-control" name="" id="" disabled>
                        <option value="EHPI">EHP 실내기</option>
                    </select>
                </div>
                <div class="col-6">
                    <select v-model="zoneIdx" class="form-control" name="" id="" @change="searchEquipGroup(equipGrpType, zoneIdx)">
                        <option :value="null">구역 선택</option>
                        <option v-for="(zone) in zoneInfo" :key="zone.zoneIdx" :value="zone.zoneIdx">{{ zone.zoneName }}
                        </option>
                    </select>
                </div>
            </form>
            <form class="form-group row">
                <div class="col-12">
                    <div
                        style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px; height:150px; max-height: 150px; overflow-y: auto;">
                        <div v-for="(equipGroup, i) in equipGroupList" :key="equipGroup.equipGrpIdx"
                            class="form-check mt-1">
                            <input v-model="checkEquipGroupList[i]" class="form-check-input" type="checkbox" :id="`ehpGourp${i}`"
                                @change="selectEquipGroup()">
                            <label class="form-check-label" :for="`ehpGourp${i}`">{{ equipGroup.equipGrpName }}</label>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal centered ref="ehpModal" id="ehpModal">
            <template #modal-header>
                <span style="font-size: 14px; font-weight: bold;">설비 선택</span>
            </template>
            <template #modal-footer>
                <button v-if="setScheduleDetail" type="button" class="btn btn-sm btn-primary" @click="setEditEquipList()">선택</button>
                <button v-else type="button" class="btn btn-sm btn-warning" @click="setEquipList()">선택</button>
                <button type="button" class="btn btn-sm btn-secondary" @click="closeEquipModal()">닫기</button>
            </template>
            <form class="form-group row align-items-center">
                <div class="col-6">
                    <select v-model="equipType" class="form-control" name="" id="" disabled>
                        <!-- <option value="">설비유형</option>
                        <option v-for="(type, i) in equipTypeList" :key="i" :value="type.equipTypeCode">{{
                            type.equipTypeCodeName }}</option> -->
                            <option value="IDU">EHP 실내기</option>
                    </select>
                </div>
                <div class="col-6">
                    <select v-model="zoneIdx" class="form-control" name="" id="" @change="searchEquip(equipType, zoneIdx)">
                        <option :value="null">구역 선택</option>
                        <option v-for="(zone) in zoneInfo" :key="zone.zoneIdx" :value="zone.zoneIdx">{{ zone.zoneName }}
                        </option>
                    </select>
                </div>
            </form>
            <form class="form-group row">
                <div class="col-12">
                    <div
                        style="border: 1px solid #d5dbe0; border-radius: 5px; padding: 10px; height:150px; max-height: 150px; overflow-y: auto;">
                        <div v-if="equipList">
                            <div v-for="(list, i) in equipList" :key="i" class="form-check  mt-1">
                                <input v-model="checkEquipList[i]" class="form-check-input" type="checkbox" :id="`ehpEquip${i}`"
                                    @change="selectEquip()">
                                <label class="form-check-label" :for="`ehpEquip${i}`">{{ `${list.equipName}` }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';

export default {
    props: {
        equipGroup: Array,
        equip: Array,
        zoneInfo: Array,
    },
    data() {
        return {
            checkEquipGroupList: [],
            checkEquipList: [],
            zoneIdx: null,
            equipGrpType: "EHPI",
            equipType: "IDU",
            equipAllGroupList: null,
            equipAllList: null,
            equipList: null,
            equipGrpList: [],
            equipGroupList: null,
            selectEquipGroupList: [],
            selectEquipList: [],
            equipTypeList: [],
            equipEHPIGrp: null,
            equipEHPList: [],
            setEquipGrpCheckList: [],
            setEquipCheckList: [],
        }
    },
    computed: {
        setScheduleDetail() {
            return this.$store.getters.SET_SCHEDULE_DETAIL;
        }
    },
    async created() {
        await this.setEquipTypeList();
        await this.getEquipGroupList();
        await this.getEquipList();
        if (this.setScheduleDetail) {
            await this.getEquipGrpList();
        }
    },
    methods: {
        async setEquipTypeList() {
            let equipAllTypeList = await backEndApi.equipTypes.getEquipTypeList();
            for (let i = 0; i < equipAllTypeList.data.length; i++) {
                // let result = await backEndApi.equipType.getEquipType(equipAllTypeList.data[i].equipTypeCode);
                // console.log(result.data);
                if (equipAllTypeList.data[i].equipType == "IDU") {
                    this.equipTypeList.push(equipAllTypeList.data[i])
                }
            }
            console.log(this.equipTypeList);
        },
        async getEquipGrpList() {
            if (this.setScheduleDetail.groups) {
                for (let i = 0; i < this.setScheduleDetail.groups.length; i++) {
                    if (this.setScheduleDetail.groups[i].equipTypeCode == "EHPI") {
                        let result = await backEndApi.equipInfo.getEquipGroupList(this.setScheduleDetail.groups[i].equipTypeCode);
                        console.log(result.data)
                    }
                }
                this.equipEHPIGrp = this.equipGrpList.filter((v) => v.equipTypeCode == "EHPI");
            }

        },
        async getEquipList() {
            try {
                let result = await backEndApi.equipInfo.getEquipList();
                this.equipAllList = result.data;
                console.log(this.equipAllList);
            } catch (e) {
                console.error(e);
            }
        },
        async getEquipGroupList() {
            try {
                let result = await backEndApi.equipInfo.getEquipGroupList();
                this.equipAllGroupList = result.data;
                console.log(this.equipAllGroupList);
            } catch (e) {
                console.error(e);
            }
        },
        async searchEquipGroup(equipTypeCode, zoneIdx) {
            try {
                let result;

                if (zoneIdx == 1) {
                    this.checkEquipGroupList = [];
                    result = await backEndApi.equipInfo.getEquipGrpList(equipTypeCode);
                    this.equipGroupList = result.data;
                } else if (zoneIdx == 2) {
                    this.checkEquipGroupList = [];
                    result = await backEndApi.equipInfo.getEquipGrpList(equipTypeCode, zoneIdx);
                    this.equipGroupList = result.data;
                } else if (zoneIdx == 3) {
                    this.checkEquipGroupList = [];
                    result = await backEndApi.equipInfo.getEquipGrpList(equipTypeCode, zoneIdx);
                    this.equipGroupList = result.data;
                } else if (zoneIdx == 4) {
                    this.checkEquipGroupList = [];
                    result = await backEndApi.equipInfo.getEquipGrpList(equipTypeCode, zoneIdx);
                    this.equipGroupList = result.data;
                } else {
                    this.checkEquipGroupList = [];
                    result = await backEndApi.equipInfo.getEquipGrpList();
                    this.equipGroupList = null;
                }
            } catch (e) {
                console.error(e);
            }
        },
        // async searchEquip(equipTypeCode, zoneIdx) {
        //     try {
        //         let result;

        //         if (zoneIdx == 1) {
        //             this.checkEquipList = [];
        //             result = await backEndApi.equipInfo.getEquipList(equipTypeCode);
        //             this.equipList = result.data;
        //         } else if (zoneIdx == 2) {
        //             this.checkEquipList = [];
        //             result = await backEndApi.equipInfo.getEquipList(equipTypeCode, zoneIdx);
        //             this.equipList = result.data;
        //         } else if (zoneIdx == 3) {
        //             this.checkEquipList = [];
        //             result = await backEndApi.equipInfo.getEquipList(equipTypeCode, zoneIdx);
        //             this.equipList = result.data;
        //         } else if (zoneIdx == 4) {
        //             this.checkEquipList = [];
        //             result = await backEndApi.equipInfo.getEquipList(equipTypeCode, zoneIdx);
        //             this.equipList = result.data;
        //         } else {
        //             this.checkEquipList = [];
        //             result = await backEndApi.equipInfo.getEquipList();
        //             this.equipList = null;
        //         }
        //     } catch (e) {
        //         console.error(e);
        //     }
        // },
        async searchEquip(equipType) {
            try {
                let result = await backEndApi.equips.getEquipList(equipType);
                if(result.data) this.equipList = result.data;
                console.log(result.data);
            } catch (e) {
                console.error(e);
            }
        },
        selectEquipGroup() {
            this.selectEquipGroupList = [];
            for (let i = 0; i < this.equipGroupList.length; i++) {
                if (this.checkEquipGroupList[i] == true) {
                    this.selectEquipGroupList.push({
                        equipGrpIdx: this.equipGroupList[i].equipGrpIdx,
                        equipTypeCode: this.equipGroupList[i].equipTypeCode
                    })
                } else {
                    const indexToRemove = this.selectEquipGroupList.findIndex(item => item.equipGrpIdx === this.equipGroupList[i].equipGrpIdx);
                    if (indexToRemove !== -1) {
                        this.selectEquipGroupList.splice(indexToRemove, 1);
                    }
                }
            }
        },
        selectEquip() {
            this.selectEquipList = [];
            for (let i = 0; i < this.equipList.length; i++) {
                if (this.checkEquipList[i] == true) {
                    this.selectEquipList.push({
                        equipIdx: this.equipList[i].equipIdx,
                        equipType: this.equipList[i].equipType,
                        enabled: this.equipList[i].enabled
                    })
                } else {
                    const indexToRemove = this.selectEquipList.findIndex(item => item.equipIdx === this.equipList[i].equipIdx);
                    if (indexToRemove !== -1) {
                        this.selectEquipList.splice(indexToRemove, 1);
                    }
                }
            }
        },
        setEquipGroupList() {
            // this.equipGroup = this.selectEquipGroupList;
            this.selectEquipGroupList.forEach((v) => {
                this.equipGroup.push(v);
            })
            this.$refs.ehpGroupModal.hide();
            this.checkEquipGroupList = [];
            this.zoneIdx = null;
        },
        setEditEquipGroupList() {
            // this.equipGroup = this.selectEquipGroupList;
            this.selectEquipGroupList.forEach((v) => {
                this.setScheduleDetail.groups.push(v);
            })
            this.$refs.ehpGroupModal.hide();
            this.checkEquipGroupList = [];
            this.zoneIdx = null;
        },
        closeEquipGroupModal() {
            this.zoneIdx = null;
            this.equipGroupList = null;
            this.$refs.ehpGroupModal.hide();
        },
        setEquipList() {
            // this.equip = this.selectEquipList;
            this.selectEquipList.forEach((v) => {
                this.equip.push(v);
            })
            this.$refs.ehpModal.hide();
            this.checkEquipList = [];
            this.zoneIdx = null;
        },
        setEditEquipList() {
            // this.equip = this.selectEquipList;
            this.selectEquipList.forEach((v) => {
                this.setScheduleDetail.equips.push(v);
            })
            this.$refs.ehpModal.hide();
            this.checkEquipList = [];
            this.zoneIdx = null;
        },
        closeEquipModal() {
            this.zoneIdx = null;
            this.equipList = null;
            this.$refs.ehpModal.hide();
        },
        deleteEquipGroup() {
            for (let i = this.setEquipGrpCheckList.length - 1; i >= 0; i--) {
                if (this.setEquipGrpCheckList[i]) {
                    this.equipGroup.splice(i, 1);
                }
            }
            this.setEquipGrpCheckList = [];
        },
        editDeleteEquipGroup() {
            for (let i = this.setEquipGrpCheckList.length - 1; i >= 0; i--) {
                if (this.setEquipGrpCheckList[i]) {
                    this.setScheduleDetail.groups.splice(i, 1);
                }
            }
            this.setEquipGrpCheckList = [];
        },
        deleteEquip() {
            for (let i = this.setEquipCheckList.length - 1; i >= 0; i--) {
                if (this.setEquipCheckList[i]) {
                    this.equip.splice(i, 1);
                }
            }
            this.setEquipCheckList = [];
        },
        editDeleteEquip() {
            for (let i = this.setEquipCheckList.length - 1; i >= 0; i--) {
                if (this.setEquipCheckList[i]) {
                    this.setScheduleDetail.equips.splice(i, 1);
                }
            }
            this.setEquipCheckList = [];
        },
        openEhpGroupModal() {
            this.$refs.ehpGroupModal.show()
        },
        openEhpModal() {
            this.$refs.ehpModal.show()
        }
    }
}

</script>

<style scoped></style>